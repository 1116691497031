import React from "react";
import Labels from "./Labels";
export default function Sidebar() {
  return (
    <aside
      style={{
        border: "1px solid #d6d6d6",
        padding: "10px",
        minWidth: "150px",
      }}
    >
      <Labels />
    </aside>
  );
}
