import dayjs from "dayjs";
import React, { useContext } from "react";
import SetariProg from "../../setariProg/SetariProg";
import { luni } from "../ro";
import GlobalContext from "../context/GlobalContext";
import styles from "./calendarHeader.module.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function CalendarHeader() {
  const { monthIndex, setMonthIndex } = useContext(GlobalContext);
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    const toCurrentDay = document.getElementById("currentDay");
    toCurrentDay?.scrollIntoView();
    setMonthIndex(
      monthIndex === dayjs().month() ? monthIndex : dayjs().month()
    );
  }

  const an = dayjs(new Date(dayjs().year(), monthIndex)).format(`YYYY`);

  const luna = dayjs(new Date(dayjs().year(), monthIndex)).format(`MMMM`);

  return (
    <div className={`${styles.setari}`}>
      <header className={`${styles.headerCalendar}`}>
        <h1 className={`${styles.titlu}`}>Programator</h1>
        <button onClick={handleReset} className={`${styles.buttonCalendar}`}>
          Astazi
        </button>
        <button onClick={handlePrevMonth}>
          <LeftOutlined className={`${styles.schimbatLuna}`} />
        </button>
        <button onClick={handleNextMonth}>
          <RightOutlined className={`${styles.schimbatLuna}`} />
        </button>
        <h2 className={`${styles.dataCalendar}`}>
          {luni(luna)} {an}
        </h2>
      </header>
      <SetariProg />
      {/* <SettingOutlined
        className={`${styles.schimbatLuna}`}
        style={{ fontSize: "25px" }}
      /> */}
    </div>
  );
}
