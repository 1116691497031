import axios from "axios";

const apiUrl = "https://api.autoviva.ro/uploads/";
// const apiUrl = "http://localhost:3500/uploads/";

export const singleFileUpload = async (data, options) => {
  try {
    await axios.post(apiUrl + "singleFile", data, options);
  } catch (error) {
    throw error;
  }
};
export const getSingleFiles = async (id) => {
  try {
    const { data } = await axios.get(apiUrl + `getSingleFiles/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteFile = async (data) => {
  try {
    await axios.delete(apiUrl + `deleteFile/${data._id}`);
  } catch (error) {
    throw error;
  }
};
