import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import styles from "./Deviz.module.css";
import { Modal, Button as Button2, Input, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { manoperaInitial } from "../initialState";

const ManoperaDeviz = ({
  manopera,
  setManopera,
  listaManopere,
  setListaManopere,
  setValoareTotalaManoperaFaraTva,
  TAX_RATE,
  mechanicsId,
  setMechanicsId,
  mechanics,
}) => {
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const onPiesaChanged = (e) => {
    const { name, value } = e.target;
    setManopera({
      ...manopera,
      [name]: value,
    });
  };

  const handleClickListaPreturi = (e) => {
    e.preventDefault();

    const canSave = [manopera.descriere, manopera.um].every(Boolean);
    if (canSave) {
      if (editingItem === null) {
        setListaManopere([
          ...listaManopere,
          {
            id: Math.floor(Math.random() * 10000000000001),
            cod: manopera.cod,
            descriere: manopera.descriere.toUpperCase(),
            cantitate: manopera.cantitate,
            um: manopera.um.toUpperCase() || "ORA",
            pretUnitarCuTva: manopera.pretUnitarCuTva,
            pretUnitarFaraTva: manopera.pretUnitarFaraTva,
            pretFaraTva: manopera.pretFaraTva,
            mecanic: mechanicsId,
          },
        ]);
        message.success("Manopera a fost adaugata!");
        setManopera(manoperaInitial);
      } else {
        const editManopera = listaManopere.map((obj) => {
          if (obj.id === editingItem.id) {
            return {
              ...obj,
              cod: manopera.cod,
              descriere: manopera.descriere.toUpperCase(),
              cantitate: manopera.cantitate,
              um: manopera.um.toUpperCase() || "ORA",
              pretUnitarCuTva: manopera.pretUnitarCuTva,
              pretUnitarFaraTva: manopera.pretUnitarFaraTva,
              pretFaraTva: manopera.pretFaraTva,
              mecanic: mechanicsId,
            };
          }
          return obj;
        });
        setListaManopere(editManopera);
        message.success("Manopera a fost modificata!");
        setEditingItem(null);
        setAddEditModalVisibilty(false);
      }
    } else {
      !manopera.descriere &&
        message.error(`Va rugam sa introduceti o descriere!`);
      !manopera.um &&
        message.error(`Va rugam sa introduceti o unitate de masura!`);
    }
  };

  const handleRemoveField = (id) => {
    const newList = listaManopere.filter((manopera) => manopera.id !== id);
    setListaManopere(newList);
  };

  const manopereSubtotal = subtotalManopera(listaManopere);

  useEffect(() => {
    const calcFaraTva = () => {
      const pretUnitarFaraTva = (
        manopera.pretUnitarCuTva /
        (TAX_RATE + 1)
      ).toFixed(2);
      const pretFaraTva = Number(
        (pretUnitarFaraTva * manopera.cantitate).toFixed(2)
      );
      setManopera({
        ...manopera,
        pretUnitarFaraTva: pretUnitarFaraTva,
        pretFaraTva: pretFaraTva,
      });
    };
    calcFaraTva();
    setValoareTotalaManoperaFaraTva(manopereSubtotal.toFixed(2));
    // eslint-disable-next-line
  }, [
    manopera.pretUnitarCuTva,
    manopera.adaos,
    manopera.cantitate,
    manopereSubtotal,
    TAX_RATE,
    // manopera,  daca o pun randeaza in continuu
    setManopera,
    setValoareTotalaManoperaFaraTva,
  ]);

  function subtotalManopera(items) {
    return items
      .map(({ pretFaraTva }) => pretFaraTva)
      .reduce((sum, i) => sum + i, 0);
  }

  const onMecaniciIdChanged = (e) => setMechanicsId(e.target.value);

  const optionsMecanici = mechanics.map((mecanic) => {
    return (
      <option key={mecanic.id} value={mecanic.id}>
        {mecanic.name}
      </option>
    );
  });

  function getMecanicName(id) {
    let foundName = mechanics.find((e) => e.id === id);
    return foundName.name;
  }

  return (
    <>
      <Grid
        item
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Container>
          <div className="d-flex justify-content-between">
            <Typography
              variant="overline"
              style={{ color: "gray", paddingRight: "3px" }}
              gutterBottom
            >
              Manopera:
            </Typography>

            <Button2
              style={{ width: 145 }}
              type="primary"
              onClick={() => {
                setAddEditModalVisibilty(true);
                setManopera(manoperaInitial);
              }}
            >
              Adauga manopera
            </Button2>
          </div>
        </Container>
        <Container>
          <TableContainer component={Paper} className="tb-container">
            <Table
              style={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nr.Crt</TableCell>
                  <TableCell align="center">Cod</TableCell>
                  <TableCell align="center">Descriere</TableCell>
                  <TableCell align="center">Cant.</TableCell>
                  <TableCell align="center">U.M.</TableCell>
                  <TableCell align="center">PU fara TVA</TableCell>
                  <TableCell align="center">Val. fara TVA</TableCell>
                  <TableCell align="center">Mecanic</TableCell>
                  <TableCell align="center">Actiuni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaManopere?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.cod}</TableCell>
                    <TableCell align="left">{row.descriere}</TableCell>
                    <TableCell align="center">{row.cantitate}</TableCell>
                    <TableCell align="center">{row.um}</TableCell>
                    <TableCell align="center">
                      {row.pretUnitarFaraTva}
                    </TableCell>
                    <TableCell align="center">{row.pretFaraTva}</TableCell>
                    <TableCell align="center">
                      <span>{getMecanicName(row.mecanic)}</span>
                    </TableCell>

                    <TableCell align="center">
                      <EditOutlined
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => {
                          setEditingItem(row);
                          setAddEditModalVisibilty(true);
                          setManopera(row);
                        }}
                      />
                      {"  "}
                      <DeleteOutlined
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => handleRemoveField(row.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} />
                  <TableCell colSpan={4} align="right">
                    Total valoare manopera de schimb (fara TVA)
                  </TableCell>
                  <TableCell align="center">
                    {manopereSubtotal.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>

      {/* adaugare si editare manopere */}
      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null);
            setAddEditModalVisibilty(false);
          }}
          open={addEditModalVisibilty}
          centered
          title={`${
            editingItem !== null ? "Modifica manopera" : "Adauga manopera"
          }`}
          footer={false}
        >
          <Grid>
            <Typography variant="body3" style={{}}>
              <div className={styles.inputDivForm}>
                <div style={{ width: 38 }}>Cod:</div>
                <Input
                  type="text"
                  autoComplete="off"
                  name="cod" //IMPORTANT
                  label="Cod"
                  value={manopera.cod}
                  onChange={onPiesaChanged}
                />{" "}
              </div>
              <div className={styles.inputDivForm}>
                <div style={{ width: 78 }}>Descriere:</div>
                <Input
                  type="text"
                  autoComplete="off"
                  name="descriere" //IMPORTANT
                  label="Descriere"
                  value={manopera.descriere}
                  onChange={onPiesaChanged}
                />{" "}
              </div>
              <div className={styles.inputDivJos}>
                <div className={styles.inputDivForm2}>
                  Cantitate:{" "}
                  <Input
                    style={{ width: 55 }}
                    type="text"
                    autoComplete="off"
                    name="cantitate" //IMPORTANT
                    label="Cantitate"
                    value={manopera.cantitate}
                    onChange={onPiesaChanged}
                  />{" "}
                </div>
                <div className={styles.inputDivForm2}>
                  U.M.:{" "}
                  <Input
                    style={{ width: 60 }}
                    type="text"
                    name="um" //IMPORTANT
                    label="UM"
                    value={manopera.um}
                    onChange={onPiesaChanged}
                  />{" "}
                </div>

                <div className={styles.inputDivForm2}>
                  Valuare cu tva:{" "}
                  <Input
                    style={{ width: 80 }}
                    type="number"
                    autoComplete="off"
                    name="pretUnitarCuTva" //IMPORTANT
                    label="Pret unitar cu tva"
                    value={manopera.pretUnitarCuTva}
                    onChange={onPiesaChanged}
                  />{" "}
                </div>

                <div className={styles.inputDivForm2}>
                  Pret unitar fara tva:{" "}
                  <Input
                    style={{ width: 80 }}
                    type="number"
                    name="pretUnitarFaraTva" //IMPORTANT
                    label="pretUnitarFaraTva"
                    value={manopera.pretUnitarFaraTva}
                    onChange={onPiesaChanged}
                    disabled
                  />{" "}
                </div>
                <div className={styles.inputDivForm2}>
                  Pret fara tva:{" "}
                  <Input
                    style={{ width: 80 }}
                    type="number"
                    name="pretFaraTva" //IMPORTANT
                    label="PretFaraTva"
                    value={manopera.pretFaraTva}
                    onChange={onPiesaChanged}
                    disabled
                  />{" "}
                </div>

                <div className={styles.inputDivForm2}>
                  Mecanic:{" "}
                  <select value={mechanicsId} onChange={onMecaniciIdChanged}>
                    {optionsMecanici}
                  </select>{" "}
                </div>
              </div>
            </Typography>
          </Grid>

          <div className="d-flex justify-content-end">
            <Button2 type="primary" onClick={handleClickListaPreturi}>{`${
              editingItem !== null ? "Salveaza" : "Adauga"
            }`}</Button2>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManoperaDeviz;
