import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import { saptamani } from "../ro";
import styles from "./day.module.css";

export default function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
    monthIndex,
  } = useContext(GlobalContext);

  const month = dayjs().month();
  if (monthIndex === month) {
    const toCurrentDay = document.getElementById("currentDay");
    toCurrentDay?.scrollIntoView({ block: "center" });
  } else {
    const top = document.getElementById("lista");
    top.scrollIntoView();
  }

  useEffect(() => {
    const events = filteredEvents.filter(
      (evt) => dayjs(evt.day).format("DD-MM-YY") === day.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [filteredEvents, day]);

  const sapt = day.format("dddd").toUpperCase();

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? styles.zile3
      : "";
  }

  function getCurrentDay() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? true : false;
  }

  function deleteWeekend(ziSapt) {
    return ziSapt === "SAMBATA" || ziSapt === "DUMINICA" ? true : false;
  }

  return (
    <>
      <div key={rowIdx} className={`${styles.rand}`}>
        <div className={`${styles.zile0}`}>
          <div className={`${styles.zile}`}>
            <p className={`${styles.zile1}`}>{saptamani(sapt)}</p>
            <p className={`${styles.zile2}  ${getCurrentDayClass()}`}>
              {day.format("DD.MM.YYYY")}
            </p>
            {getCurrentDay() && <div id="currentDay"></div>}
          </div>
        </div>

        <div
          onClick={() => {
            !deleteWeekend(saptamani(sapt)) && setDaySelected(day);
            !deleteWeekend(saptamani(sapt)) && setShowEventModal(true);
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            cursor: !deleteWeekend(saptamani(sapt)) && "pointer",
            backgroundColor:
              deleteWeekend(saptamani(sapt)) && "rgba(254, 0, 0, 0.10)",
          }}
        >
          {dayEvents.map((evt, idx) => (
            <>
              <div
                key={idx}
                onClick={() => setSelectedEvent(evt)}
                className={`${styles.programare}`}
                style={{ backgroundColor: `${evt.label}` }}
              >
                <strong>{evt.title}</strong>
                <div>
                  {evt.vin} - {evt.tel}
                </div>
                <div>{evt.description}</div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
