import React, { useEffect, useState } from "react";
import {
  useGetClientsQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useAddNewClientMutation,
} from "./clientsApiSlice";
import {
  useGetCarsQuery,
  useUpdateCarMutation,
  useDeleteCarMutation,
  useAddNewCarMutation,
} from "../cars/carsApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import {
  DeleteOutlined,
  EditOutlined,
  CarOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import {
  Divider,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Table,
} from "antd";
import ListaIstoricDevize from "./ListaIstoricDevize";
import { useNavigate } from "react-router-dom";

const JUDETERO = [
  "Alba",
  "Arad",
  "Argeș",
  "Bacău",
  "Bihor",
  "Bistrița-Năsăud",
  "Botoșani",
  "Brașov",
  "Brăila",
  "București",
  "Buzău",
  "Caraș-Severin",
  "Călărași",
  "Cluj",
  "Constanța",
  "Covasna",
  "Dâmbovița",
  "Dolj",
  "Galați",
  "Giurgiu",
  "Gorj",
  "Harghita",
  "Hunedoara",
  "Ialomița",
  "Iași",
  "Ilfov",
  "Maramureș",
  "Mehedinți",
  "Mureș",
  "Neamț",
  "Olt",
  "Prahova",
  "Satu Mare",
  "Sălaj",
  "Sibiu",
  "Suceava",
  "Teleorman",
  "Timiș",
  "Tulcea",
  "Vaslui",
  "Vâlcea",
  "Vrancea",
];

function ClientsList() {
  const [clientsData, setClientsData] = useState([]);
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [carsData, setCarsData] = useState([]);
  const [addEditCarsModalVisibilty, setAddEditCarsModalVisibilty] =
    useState(false);
  const [editingCarsItem, setEditingCarsItem] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResultsClient, setSearchResultsClient] = useState([]);
  const [searchResultsCar, setSearchResultsCar] = useState([]);

  const { id } = useAuth();

  const navigate = useNavigate();

  const {
    data: clients,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetClientsQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: cars, isSuccess: iscarSuccess } = useGetCarsQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [deleteClient] = useDeleteClientMutation();
  const [addNewClient] = useAddNewClientMutation();
  const [updateClient] = useUpdateClientMutation();

  const [deleteCar] = useDeleteCarMutation();
  const [addNewCar] = useAddNewCarMutation();
  const [updateCar] = useUpdateCarMutation();

  const onDeleteClientClicked = async (record) => {
    try {
      message.success("Clientului a fost sters cu succes");
      await deleteClient({ id: record }).unwrap();
    } catch (err) {
      message.error(
        "Nu se poate sterge acest client, exista informatii in baza de date legate de el"
      );
    }
  };

  const onDeleteCarClicked = async (record) => {
    try {
      message.success("Vehiculul a fost sters cu succes");
      await deleteCar({ id: record }).unwrap();
    } catch (err) {
      message.error(
        "Nu se poate sterge acest vehicul, exista informatii in baza de date legate de el"
      );
    }
  };

  const getClients = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(clients?.entities).map(
        (key) => clients.entities[key]
      );
      setClientsData(result);
    }
  };
  const getCars = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (iscarSuccess) {
      var result = Object.keys(cars?.entities).map((key) => cars.entities[key]);
      setCarsData(result);
    }
  };

  useEffect(() => {
    getClients();
    getCars();
    // eslint-disable-next-line
  }, [clients, cars]);

  //__________________________________________________________________
  //   tabelul extins cu vehicule clienti
  const tabelVehicule = (record) => {
    const clientId = record;

    let filteredIds = searchResultsCar.filter((e) => e.client === clientId);

    const columns = [
      {
        title: "Marca",
        dataIndex: "marca",
      },
      {
        title: "Model",
        dataIndex: "model",
      },
      {
        title: "Numar inmatriculare",
        dataIndex: "nrinmatriculare",
      },
      {
        title: "Serie sasiu",
        dataIndex: "vin",
      },
      {
        title: "Data fabricatiei",
        dataIndex: "fabricatie",
      },
      {
        title: "Capacitatea cilindrica",
        dataIndex: "capacitatecil",
      },
      {
        title: "Puterea motorului",
        dataIndex: "kw",
      },
      {
        title: "Modificari",
        dataIndex: "id",
        render: (id, record) => (
          <div className="d-flex">
            <DashboardOutlined
              className="mx-2"
              onClick={() => {
                navigate("/dash/devize/new");
                localStorage.setItem("idCar", record.id);
              }}
            />
            <EditOutlined
              className="mx-2"
              onClick={() => {
                setEditingCarsItem(record);
                setAddEditCarsModalVisibilty(true);
              }}
            />
            <DeleteOutlined
              className="mx-2"
              onClick={() => onDeleteCarClicked(id)}
            />
          </div>
        ),
      },
    ];
    return (
      <>
        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => setAddEditCarsModalVisibilty(true)}
          >
            Adauga Vehicul
          </Button>
        </div>

        {filteredIds.length === 0 && (
          <p className="errmsg">
            {"Nu exista vehicule in baza de date pentru acest client"}
          </p>
        )}

        {filteredIds.length > 0 && (
          <Table
            columns={columns}
            dataSource={filteredIds}
            pagination={false}
          />
        )}

        <ListaIstoricDevize clientId={clientId} userId={id} />

        {addEditCarsModalVisibilty && (
          <Modal
            onCancel={() => {
              setEditingCarsItem(null);
              setAddEditCarsModalVisibilty(false);
            }}
            open={addEditCarsModalVisibilty}
            centered
            title={`${
              editingCarsItem !== null ? "Modifica vehicul" : "Adauga vehicul"
            }`}
            footer={false}
          >
            <Form
              initialValues={{
                ...editingCarsItem,
                clientN: clientId,
              }}
              layout="vertical"
              onFinish={onFinishCars}
            >
              <Form.Item name="id" hidden>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item name="user" hidden>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item name="client" hidden>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item name="clientN" hidden>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item name="marca" label="Marca">
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>

              <Form.Item name="model" label="Model">
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>

              <Form.Item name="nrinmatriculare" label="Numar inmatriculare">
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>

              <Form.Item name="vin" label="Serie sasiu">
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>

              <Form.Item name="fabricatie" label="Data fabricatiei">
                <Input />
              </Form.Item>

              <Form.Item name="capacitatecil" label="Capacitatea cilindrica">
                <Input type="number" />
              </Form.Item>

              <Form.Item name="kw" label="Puterea motorului">
                <Input type="number" />
              </Form.Item>

              <div className="d-flex justify-content-end">
                <Button htmlType="submit" type="primary">
                  {`${editingCarsItem !== null ? "Salveaza" : "Adauga"}`}
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  };

  const onFinishCars = async (values) => {
    if (editingCarsItem === null) {
      const canSave = [values.clientN, id].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await addNewCar({
            user: id,
            client: values.clientN,
            marca: values.marca,
            model: values.model,
            nrinmatriculare: values.nrinmatriculare,
            vin: values.vin,
            fabricatie: values.fabricatie,
            capacitatecil: values.capacitatecil,
            kw: values.kw,
          }).unwrap();
          /// se pune unrap ca este in interiorul try catch block
          message.success("Vehiculul a fost adaugat cu succes");
          setAddEditCarsModalVisibilty(false);
        } catch (err) {
          message.error({
            content: err?.data?.message,
            duration: 7,
          });
        }
      } else {
        message.error("Introdu datele obligatorii!");
      }
    } else {
      const canSave = [values.client, values.user].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await updateCar({
            id: values.id,
            user: values.user,
            client: values.client,
            marca: values.marca,
            model: values.model,
            nrinmatriculare: values.nrinmatriculare,
            vin: values.vin,
            fabricatie: values.fabricatie,
            capacitatecil: values.capacitatecil,
            kw: values.kw,
          }).unwrap();

          message.success("Datele vehiculului modificate cu succes");
          setAddEditCarsModalVisibilty(false);
          setEditingCarsItem(null);
        } catch (err) {
          message.error({
            content: err?.data?.message,
            duration: 7,
          });
        }
      } else {
        message.error("Introdu date in toate campurile");
      }
    }
  };

  //__________________________________________________________________

  const columns = [
    {
      title: "Nume client/ firma",
      dataIndex: "name",
      align: "center",
    },

    {
      title: "Adresa",
      dataIndex: "adresa",
      align: "center",
    },
    {
      title: "Localitate",
      dataIndex: "localitate",
      align: "center",
    },
    {
      title: "Judet",
      dataIndex: "judet",
      align: "center",
    },
    {
      title: "CNP / CUI firma",
      dataIndex: "cuicnp",
      align: "center",
    },
    {
      title: "Nume contact",
      dataIndex: "contact",
      align: "center",
    },
    {
      title: "Telefon",
      dataIndex: "telefon",
      align: "center",
    },

    Table.EXPAND_COLUMN,
    {
      title: "Modificari",
      dataIndex: "id",
      align: "center",
      render: (id, record) => (
        <div className="d-flex">
          <EditOutlined
            className="mx-2"
            onClick={() => {
              setEditingItem(record);
              setAddEditModalVisibilty(true);
            }}
          />
          <DeleteOutlined
            className="mx-2"
            onClick={() => onDeleteClientClicked(id)}
          />
        </div>
      ),
    },
  ];

  const onFinish = async (values) => {
    if (editingItem === null) {
      const canSave = [values.name, id].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await addNewClient({
            user: id,
            name: values.name,
            adresa: values.adresa,
            localitate: values.localitate,
            judet: values.judet,
            cuicnp: values.cuicnp,
            contact: values.contact,
            telefon: values.telefon,
          }).unwrap();
          /// se pune unrap ca este in interiorul try catch block
          message.success("Clientul a fost adaugat cu succes");
          setAddEditModalVisibilty(false);
        } catch (err) {
          message.error("Nu se poate salva acest client", err);
        }
      } else {
        message.error("Introdu un nume de client");
      }
    } else {
      const canSave = [values.name, values.user].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await updateClient({
            id: values.id,
            user: values.user,
            name: values.name,
            adresa: values.adresa,
            localitate: values.localitate,
            judet: values.judet,
            cuicnp: values.cuicnp,
            contact: values.contact,
            telefon: values.telefon,
          }).unwrap();

          message.success("Datele clientului modificate cu succes");
          setAddEditModalVisibilty(false);
          setEditingItem(null);
        } catch (err) {
          message.error("Nu se poate modifica acest client");
        }
      } else {
        message.error("Introdu un nume de client");
      }
    }
  };

  let content;

  const handleChange = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    const resultsCar = carsData?.filter(
      (o) =>
        o.vin.toLowerCase().includes(searchTerm.toLowerCase()) ||
        o.nrinmatriculare.toLowerCase().includes(searchTerm.toLowerCase())
    );

    let filteredResultsCar = resultsCar.map((e) => e.client);

    const resultsClient = clientsData?.filter(
      (o) =>
        filteredResultsCar.includes(o.id) ||
        o.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        o.telefon.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (resultsClient.length > 0 && filteredResultsCar.length === 0) {
      setSearchResultsCar(carsData);
    } else {
      setSearchResultsCar(resultsCar);
    }

    setSearchResultsClient(resultsClient);

    // eslint-disable-next-line
  }, [searchTerm, carsData, clientsData]);

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      <div className="d-flex align-items-center">
        <h4 className="">Cautare:</h4>
        <div style={{ marginLeft: "10px" }}>
          <Input
            type="search"
            // size="68"
            style={{
              width: 300,
            }}
            value={searchTerm}
            onChange={handleChange}
            placeholder="VIN, nr. inmatriculare, nume sau telefon"
          />
        </div>
      </div>
      <Divider />
      <div className="d-flex justify-content-between">
        <h3>Clienti</h3>
        <Button type="primary" onClick={() => setAddEditModalVisibilty(true)}>
          Adauga Client
        </Button>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      {searchResultsClient.length === 0 && (
        <p className="errmsg">{"Nu s-a gasit niciun client"}</p>
      )}

      {searchResultsClient.length > 0 && (
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => tabelVehicule(record.id),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CarOutlined
                  style={{ color: "red" }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <CarOutlined onClick={(e) => onExpand(record, e)} />
              ),
            columnTitle: "Vehicule client",
            expandRowByClick: true,
          }}
          dataSource={searchResultsClient}
          bordered
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30"],
            showSizeChanger: true,
          }}
        />
      )}

      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null);
            setAddEditModalVisibilty(false);
          }}
          open={addEditModalVisibilty}
          centered
          title={`${
            editingItem !== null ? "Modifica client" : "Adauga client"
          }`}
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item name="id" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="user" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="name" label="Nume client/ firma">
              <Input />
            </Form.Item>

            <Form.Item name="adresa" label="Adresa">
              <Input />
            </Form.Item>

            <Form.Item name="localitate" label="Localitate">
              <Input />
            </Form.Item>

            <Form.Item name="judet" label="Judet">
              <Select
                placeholder="Selecteaza un judet"
                value={JUDETERO}
                style={{
                  width: "100%",
                }}
                options={JUDETERO.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>

            <Form.Item name="cuicnp" label="CNP / CUI firma">
              <Input />
            </Form.Item>

            <Form.Item name="contact" label="Nume contact">
              <Input />
            </Form.Item>

            <Form.Item name="telefon" label="Telefon">
              <Input />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <Button htmlType="submit" type="primary">
                {`${editingItem !== null ? "Salveaza" : "Adauga"}`}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );

  return content;
}

export default ClientsList;
