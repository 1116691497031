import React, { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import styles from "./labels.module.css";
import { useGetUserQuery } from "../../user/usersApiSlice";
import useAuth from "../../../hooks/useAuth";

export default function Labels() {
  const { id } = useAuth();

  const { data: user } = useGetUserQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  function getNameLabel(color) {
    let find = user?.user.tagProg.find((lbl) => lbl.color === color);
    return find.name;
  }

  const { labels, updateLabel } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <div style={{ position: "fixed" }}>
        <p
          style={{
            color: "grey",
            fontWeight: "bold",
            marginTop: "20px",
          }}
        >
          Eticheta
        </p>
        {labels.map(({ label: lbl, checked }, idx) => (
          <label
            key={idx}
            style={{
              items: "center",
              marginTop: "10px",
              display: "block",
            }}
          >
            <input
              type="checkbox"
              checked={checked}
              onChange={() => updateLabel({ label: lbl, checked: !checked })}
              className={`${styles.checkboxRound}`}
              style={{
                backgroundColor: checked ? `${lbl}` : "rgb(255, 253, 253)",
              }}
            />

            <span
              style={{
                marginLeft: "8px",
                textTransform: "capitalize",
                textDecoration: checked ? "" : "line-through",
              }}
            >
              {getNameLabel(lbl)}
            </span>
          </label>
        ))}
      </div>
    </React.Fragment>
  );
}
