import React, { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { useGetUserQuery } from "../../user/usersApiSlice";
import useAuth from "../../../hooks/useAuth";
import { luni, saptamaniMic } from "../ro";
import styles from "./eventModal.module.css";
import {
  PauseOutlined,
  CloseOutlined,
  DeleteOutlined,
  ScheduleOutlined,
  TagsOutlined,
  AlignRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";

export default function EventModal() {
  const { id } = useAuth();
  const { data: user } = useGetUserQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { setShowEventModal, daySelected, dispatchCalEvent, selectedEvent } =
    useContext(GlobalContext);

  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  const [vin, setVin] = useState(selectedEvent ? selectedEvent.vin : "");
  const [tel, setTel] = useState(selectedEvent ? selectedEvent.tel : "");

  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );
  // const [selectedLabel, setSelectedLabel] = useState(
  //   selectedEvent
  //     ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
  //     : labelsClasses[0]
  // );

  function getSelectedLabel() {
    let find = user.user.tagProg?.find(
      (lbl) => lbl.color === selectedEvent.label
    );
    return find.color;
  }

  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent ? getSelectedLabel() : user.user.tagProg[0]?.color
  );

  function handleSubmit(e) {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      vin,
      tel,
      label: selectedLabel,
      day: daySelected.valueOf(),
      id: selectedEvent ? selectedEvent.id : Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowEventModal(false);
  }

  const zi = daySelected.format("dddd");
  const luna = daySelected.format("MMMM");

  return (
    <div className={`${styles.principal}`}>
      <div className={`${styles.formModal}`}>
        <header className={`${styles.headerModal}`}>
          <PauseOutlined
            style={{ fontSize: "20px", color: "grey" }}
            rotate="90"
          />
          <div>
            {selectedEvent && (
              <DeleteOutlined
                onClick={() => {
                  dispatchCalEvent({
                    type: "delete",
                    payload: selectedEvent,
                  });
                  setShowEventModal(false);
                }}
                style={{
                  fontSize: "20px",
                  color: "grey",
                  marginRight: "5px",
                }}
              />
            )}
            <button onClick={() => setShowEventModal(false)}>
              <CloseOutlined style={{ fontSize: "20px", color: "grey" }} />
            </button>
          </div>
        </header>
        <div style={{ padding: "0.75rem" }}>
          <div className={`${styles.inputForm}`}>
            <div></div>
            <input
              type="text"
              name="title"
              placeholder="Vehicul (nr. inmatriculare)"
              value={title}
              required
              className={`${styles.inputCar}`}
              onChange={(e) => setTitle(e.target.value.toUpperCase())}
            />

            <div></div>
            <input
              type="text"
              name="vin"
              placeholder="VIN"
              value={vin}
              className={`${styles.inputCar}`}
              onChange={(e) => setVin(e.target.value.toUpperCase())}
            />

            <div></div>
            <input
              type="number"
              name="tel"
              placeholder="Nr. Telefon"
              value={tel}
              className={`${styles.inputCar}`}
              onChange={(e) => setTel(e.target.value)}
            />

            <ScheduleOutlined style={{ fontSize: "25px", color: "grey" }} />

            <p>
              {saptamaniMic(zi)} {daySelected.format("DD")} {luni(luna)}{" "}
              {daySelected.format("YYYY")}
            </p>

            <AlignRightOutlined style={{ fontSize: "22px", color: "grey" }} />
            <textarea
              type="text"
              name="description"
              placeholder="Operatiuni"
              value={description}
              required
              autoComplete="false"
              className={`${styles.inputCar}`}
              onChange={(e) => setDescription(e.target.value)}
            />

            <TagsOutlined style={{ fontSize: "23px", color: "grey" }} />
            {/* <div style={{ display: "flex", columnGap: "0.5rem" }}>
              {labelsClasses.map((lblClass, i) => (
                <span
                  key={i}
                  onClick={() => setSelectedLabel(lblClass)}
                  className={`${styles.label}`}
                  style={{ backgroundColor: `${lblClass}` }}
                >
                  {selectedLabel === lblClass && <CheckOutlined />}
                </span>
              ))}
            </div> */}

            <div
              style={{
                display: "flex",
                columnGap: "0.5rem",
              }}
            >
              {user.user.tagProg?.map((lblClass, i) => (
                <div
                  key={i}
                  onClick={() => setSelectedLabel(lblClass.color)}
                  className={`${styles.label}`}
                  style={{ backgroundColor: `${lblClass.color}` }}
                >
                  {selectedLabel === lblClass.color && <CheckOutlined />}
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer className={`${styles.footerModal}`}>
          <button onClick={handleSubmit} className={`${styles.buttonModal}`}>
            Salveaza
          </button>
        </footer>
      </div>
    </div>
  );
}
