import React from "react";
import styles from "./Deviz.module.css";
import { Grid, Container, Typography } from "@mui/material";
import { today } from "../initialState";
import { message } from "antd";

const ConstatariDeviz = ({
  valEstimativaRep,
  setValEstimativaRep,
  termenEstimativRep,
  setTermenEstimativRep,
  sesizariClient,
  setSesizariClient,
  constatareService,
  setConstatareService,
  defecteConstatateSiNeremediate,
  setDefecteConstatateSiNeremediate,
}) => {
  const onValEstimativaRepChanged = (e) => setValEstimativaRep(e.target.value);
  const onTermenEstimativRepChanged = (e) => {
    const { name, value } = e.target;
    if (name === "data" && today > value) {
      message.error(
        "Introdu o data valida! Data aleasa trebuie sa fie mai mare sau egala cu data de astazi."
      );
      setTermenEstimativRep({
        ...termenEstimativRep,
        data: today,
      });
    } else {
      setTermenEstimativRep({
        ...termenEstimativRep,
        [name]: value,
      });
    }
  };
  const onSesizariClientChanged = (e) => setSesizariClient(e.target.value);
  const onConstatareServiceChanged = (e) =>
    setConstatareService(e.target.value);
  const onDefecteConstatateSiNeremediateChanged = (e) =>
    setDefecteConstatateSiNeremediate(e.target.value);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Grid item style={{ width: "25%" }}>
          <Container sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="overline"
              style={{ color: "gray", paddingRight: "3px" }}
              gutterBottom
            >
              Estimari deviz:
            </Typography>
            <Typography variant="body3">
              Valoare estimativa reparatie:
            </Typography>
            <Typography variant="body3">
              <input
                className={styles.noOutline}
                type="number"
                autoComplete="off"
                value={valEstimativaRep}
                onChange={onValEstimativaRepChanged}
              />{" "}
              RON
            </Typography>
            <Typography variant="body3">Termen de executie:</Typography>
            <Typography variant="body3">
              <input
                className={styles.noOutlineDate}
                type="date"
                autoComplete="off"
                value={termenEstimativRep.data}
                onChange={onTermenEstimativRepChanged}
                name="data" //IMPORTANT
                label="Data"
              />{" "}
              ora{" "}
              <input
                className={styles.noOutlineOra}
                type="text"
                maxLength="2"
                value={termenEstimativRep.ora}
                onChange={onTermenEstimativRepChanged}
                name="ora" //IMPORTANT
                label="Ora"
              />
              :
              <input
                className={styles.noOutlineOra}
                type="text"
                maxLength="2"
                value={termenEstimativRep.minutul}
                onChange={onTermenEstimativRepChanged}
                name="minutul" //IMPORTANT
                label="Minutul"
              />
            </Typography>
          </Container>
        </Grid>
        <Grid item style={{ width: "75%" }}>
          <Container>
            <Typography
              variant="overline"
              style={{ color: "gray", paddingRight: "3px" }}
              gutterBottom
            >
              Sesizari client:
            </Typography>
            <Typography variant="body3">
              <textarea
                className={styles.sesisaziConstatari}
                type="text"
                autoComplete="off"
                value={sesizariClient}
                onChange={onSesizariClientChanged}
              />
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Container>
          <Typography
            variant="overline"
            style={{ color: "gray", paddingRight: "3px" }}
            gutterBottom
          >
            Constatari service:
          </Typography>
          <Typography variant="body3">
            <textarea
              className={styles.sesisaziConstatari}
              type="text"
              autoComplete="off"
              value={constatareService}
              onChange={onConstatareServiceChanged}
            />
          </Typography>
        </Container>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Container>
          <Typography
            variant="overline"
            style={{ color: "gray", paddingRight: "3px" }}
            gutterBottom
          >
            Defecte constatate si neremediate:
          </Typography>
          <Typography variant="body3">
            <textarea
              className={styles.sesisaziConstatari}
              type="text"
              autoComplete="off"
              value={defecteConstatateSiNeremediate}
              onChange={onDefecteConstatateSiNeremediateChanged}
            />
          </Typography>
        </Container>
      </Grid>
    </>
  );
};

export default ConstatariDeviz;
