export const initialState = {
  km: 0,
  nivelDevizCarburant: 0,
  valEstimativaRep: 0,
  termenEstimativRep: "",
  sesizariClient: "",
  constatareService: "",
  defecteConstatateSiNeremediate: "",
  tvaDeviz: 0,
  tva: 19,
  pretCuTva: 0,
  valoareTotalaPieseFaraTva: 0,
  valuareTotalaManoperaFaraTva: 0,
  status: "",
  tipDeviz: "CONTRACT DE REPARATII",
  numarDeviz: Math.floor(Math.random() * 100000),
};

const date = new Date();
const day = ("0" + date.getDate()).slice(-2);
const month = ("0" + (date.getMonth() + 1)).slice(-2);
export const today = date.getFullYear() + "-" + month + "-" + day;
export const todayInitial = {
  data: today,
  ora: "17",
  minutul: "00",
};

export const piesaInitial = {
  codOE: "",
  cod: "",
  descriere: "",
  cantitate: 1,
  um: "BUC",
  pretAchizitie: "",
  adaos: 30,
  pretUnitarFaraTva: 0,
  pretFaraTva: 0,
  furnizor: "",
  pieseDezmembrari: false,
  pieseDezmembrariClient: false,
};

export const manoperaInitial = {
  cod: "",
  descriere: "",
  cantitate: 0,
  um: "ORA",
  pretUnitarCuTva: "",
  pretAchizitie: 0,
  pretUnitarFaraTva: 0,
  pretFaraTva: 0,
  mecanic: "",
};
