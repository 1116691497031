import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const furnizoriAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = furnizoriAdapter.getInitialState();

export const furnizoriApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFurnizori: builder.query({
      query: (id) => ({
        url: `/furnizori/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedFurnizori = responseData.map((furnizor) => {
          furnizor.id = furnizor._id;
          return furnizor;
        });
        return furnizoriAdapter.setAll(initialState, loadedFurnizori);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Furnizor", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Furnizor", id })),
          ];
        } else return [{ type: "Furnizor", id: "LIST" }];
      },
    }),
    addNewFurnizor: builder.mutation({
      query: (initialFurnizor) => ({
        url: "/furnizori",
        method: "POST",
        body: {
          ...initialFurnizor,
        },
      }),
      invalidatesTags: [{ type: "Furnizor", id: "LIST" }],
    }),
    updateFurnizor: builder.mutation({
      query: (initialFurnizor) => ({
        url: "/furnizori",
        method: "PATCH",
        body: {
          ...initialFurnizor,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Furnizor", id: arg.id },
      ],
    }),
    deleteFurnizor: builder.mutation({
      query: ({ id }) => ({
        url: `/furnizori`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Furnizor", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetFurnizoriQuery,
  useAddNewFurnizorMutation,
  useUpdateFurnizorMutation,
  useDeleteFurnizorMutation,
} = furnizoriApiSlice;

// returns the query result object
export const selectFurnizoriResult =
  furnizoriApiSlice.endpoints.getFurnizori.select();

// creates memoized selector
const selectFurnizoriData = createSelector(
  selectFurnizoriResult,
  (furnizoriResult) => furnizoriResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllFurnizori,
  selectById: selectFurnizorById,
  selectIds: selectFurnizorIds,
  // Pass in a selector that returns the furnizori slice of state
} = furnizoriAdapter.getSelectors(
  (state) => selectFurnizoriData(state) ?? initialState
);
