import React from "react";
import ProgramariList from "../features/programari/ProgramariList";
import ContextWrapper from "../features/programari/context/ContextWrapper";

function Programari() {
  return (
    <ContextWrapper>
      <ProgramariList />
    </ContextWrapper>
  );
}

export default Programari;
