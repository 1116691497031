import React, { useState } from "react";
import { singleFileUpload } from "../../data/api";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const IstoricDeviz = ({ clientId, userId, getsingle }) => {
  const [singleFile, setSingleFile] = useState("");
  const [title, setTitle] = useState("");
  const [singleProgress, setSingleProgress] = useState(0);

  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
    setSingleProgress(0);
  };
  const singleFileOptions = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setSingleProgress(percentage);
    },
  };
  const uploadSingleFile = async () => {
    const formData = new FormData();
    formData.append("idUser", userId);
    formData.append("idDeviz", clientId);
    formData.append("title", title);
    formData.append("file", singleFile);
    await singleFileUpload(formData, singleFileOptions);
    getsingle();
  };
  return (
    <>
      <div>
        <div className="form-group">
          <div>
            <label>Date deviz</label>
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value.toUpperCase())}
              placeholder="Numarul si data devizului"
              className="form-control"
            />
          </div>
          <div
            style={{
              marginTop: "7px",
            }}
          >
            <input
              type="file"
              className="form-control"
              onChange={(e) => SingleFileChange(e)}
              accept="image/png, image/jpeg, image/jpg, application/pdf"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div className="col-10">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => uploadSingleFile()}
            >
              Incarca fisier
            </button>
          </div>
          <div className="col-2">
            <CircularProgressbar
              value={singleProgress}
              text={`${singleProgress}%`}
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: `rgba(255, 136, 136, ${singleProgress / 100})`,
                textColor: "#f88",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IstoricDeviz;
