import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const mechanicsAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = mechanicsAdapter.getInitialState();

export const mechanicsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMechanics: builder.query({
      query: (id) => ({
        url: `/mechanics/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedMechanics = responseData.map((mechanic) => {
          mechanic.id = mechanic._id;
          return mechanic;
        });
        return mechanicsAdapter.setAll(initialState, loadedMechanics);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Mechanic", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Mechanic", id })),
          ];
        } else return [{ type: "Mechanic", id: "LIST" }];
      },
    }),
    addNewMechanic: builder.mutation({
      query: (initialMechanic) => ({
        url: "/mechanics",
        method: "POST",
        body: {
          ...initialMechanic,
        },
      }),
      invalidatesTags: [{ type: "Mechanic", id: "LIST" }],
    }),
    updateMechanic: builder.mutation({
      query: (initialMechanic) => ({
        url: "/mechanics",
        method: "PATCH",
        body: {
          ...initialMechanic,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Mechanic", id: arg.id },
      ],
    }),
    deleteMechanic: builder.mutation({
      query: ({ id }) => ({
        url: `/mechanics`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Mechanic", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetMechanicsQuery,
  useAddNewMechanicMutation,
  useUpdateMechanicMutation,
  useDeleteMechanicMutation,
} = mechanicsApiSlice;

// returns the query result object
export const selectMechanicsResult =
  mechanicsApiSlice.endpoints.getMechanics.select();

// creates memoized selector
const selectMechanicsData = createSelector(
  selectMechanicsResult,
  (mechanicsResult) => mechanicsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllMechanics,
  selectById: selectMechanicById,
  selectIds: selectMechanicIds,
  // Pass in a selector that returns the mechanics slice of state
} = mechanicsAdapter.getSelectors(
  (state) => selectMechanicsData(state) ?? initialState
);
