import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useGetDevizeQuery,
  useAddNewDevizMutation,
  useUpdateDevizMutation,
} from "../devizeApiSlice";
import styles from "./Deviz.module.css";
import { Button, Grid, Container, Divider } from "@mui/material";
import { message } from "antd";
import {
  initialState,
  todayInitial,
  piesaInitial,
  manoperaInitial,
} from "../initialState";
import TipDeviz from "./TipDeviz";
import VehClient from "./VehClient";
import ConstatariDeviz from "./ConstatariDeviz";
import PieseDeviz from "./PieseDeviz";
import ManoperaDeviz from "./ManoperaDeviz";

import ListaInspectiiFinale from "./ListaInspectiiFinale";
// import AddCar from "./AddCar";
import Print from "./Print";

const TAX_RATE = 0.19; // valoarea TVA

const classes = {
  root: {
    display: "flex",
    "& > *": {
      margin: 8,
    },
  },
  large: {
    width: 96,
    height: 96,
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: 8,
    paddingLeft: 40,
    paddingRight: 8,
  },
};

function DevizNou(props) {
  const { userId, mechanics, frontOffices, furnizori, idDeviz } = props;

  const [addNewDeviz, { isLoading, isSuccess }] = useAddNewDevizMutation();

  const [updateDeviz] = useUpdateDevizMutation();

  const { data: devize } = useGetDevizeQuery(userId);

  const navigate = useNavigate();
  const location = useLocation();

  const [mechanicsId, setMechanicsId] = useState(mechanics[0].id);
  const [frontOfficesId, setFrontOfficesId] = useState(frontOffices[0].id);
  const [furnizoriId, setFurnizoriId] = useState(furnizori[0].id);
  const [type, setType] = useState(
    idDeviz ? idDeviz.tipDeviz : "CONTRACT DE REPARATII"
  );
  const [devizData, setDevizData] = useState(initialState);
  const [car, setCar] = useState(null);
  const [client, setClient] = useState(null);
  const [km, setKm] = useState(idDeviz ? idDeviz.km : "");
  const [nivelDevizCarburant, setNivelDevizCarburant] = useState(
    idDeviz ? idDeviz.nivelDevizCarburant : ""
  );
  const [valEstimativaRep, setValEstimativaRep] = useState(
    idDeviz ? idDeviz.valEstimativaRep : ""
  );
  const [termenEstimativRep, setTermenEstimativRep] = useState(
    idDeviz ? idDeviz.termenEstimativRep[0] : todayInitial
  );
  const [sesizariClient, setSesizariClient] = useState(
    idDeviz ? idDeviz.sesizariClient : ""
  );
  const [constatareService, setConstatareService] = useState(
    idDeviz ? idDeviz.constatareService : ""
  );
  const [defecteConstatateSiNeremediate, setDefecteConstatateSiNeremediate] =
    useState(idDeviz ? idDeviz.defecteConstatateSiNeremediate : "");

  const [piesa, setPiesa] = useState(piesaInitial);
  const [listaPiese, setListaPiese] = useState(idDeviz ? idDeviz.piese : []);
  const [valoareTotalaPieseFaraTva, setValoareTotalaPieseFaraTva] = useState(0);

  const [manopera, setManopera] = useState(manoperaInitial);
  const [listaManopere, setListaManopere] = useState(
    idDeviz ? idDeviz.manopera : []
  );
  const [valoareTotalaManoperaFaraTva, setValoareTotalaManoperaFaraTva] =
    useState(0);

  const [totalContractFaraTVA, setTotalContractFaraTVA] = useState(0);
  const [totalContractCuTVA, setTotalContractCuTVA] = useState(0);
  const [tvaDeviz, setTvaDeviz] = useState(0);

  const [predarePieseVechi, setPredarePieseVechi] = useState(
    idDeviz ? idDeviz.predarePieseVechi : true
  );
  const [gdpr, setGdpr] = useState(idDeviz ? idDeviz.gdpr : true);
  const [acceptPieseReconditionate, setAcceptPieseReconditionate] = useState(
    idDeviz ? idDeviz.acceptPieseReconditionate : true
  );

  const [status, setStatus] = useState(
    idDeviz ? idDeviz.status : "inAsteptare"
  );

  useEffect(() => {
    if (isSuccess) {
      // mechanicsId("");
      // frontOfficesId("");
      // furnizoriId("");
      navigate("/dash/devize");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    getTotalCount();
    // eslint-disable-next-line
  }, [location]);

  function getFormattedDate(dateString) {
    const currentDate = new Date(dateString);
    let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    let day = ("0" + currentDate.getDate()).slice(-2);
    let year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const getTotalCount = () => {
    if (idDeviz) {
      setDevizData({
        ...devizData,
        numarDeviz: idDeviz.numarDeviz,
      });
    } else {
      if (devize === undefined) {
        const primulDeviz = 0;
        setDevizData({
          ...devizData,
          numarDeviz:
            "VIV" +
            getFormattedDate(new Date()).slice(-2) +
            "PL" +
            (Number(primulDeviz) + 1).toString().padStart(6, "0"),
        });
      } else {
        const ultumul = devize.entities[Object.keys(devize.entities).pop()];
        // numarDeviz: (Number(devize.ids.length) + 1)
        //Get total count of invoice from the server and increment by one to serialized numbering of invoice
        setDevizData({
          ...devizData,
          numarDeviz:
            "VIV" +
            getFormattedDate(new Date()).slice(-2) +
            "PL" +
            (Number(ultumul.deviz) + 1).toString().padStart(6, "0"),
        });
      }
    }
  };

  const onStatusChanged = (e) => setStatus(e.target.value);

  const onFrontOfficesIdChanged = (e) => setFrontOfficesId(e.target.value);

  const optionsFrontOffices = frontOffices.map((frontOffice) => {
    return (
      <option key={frontOffice.id} value={frontOffice.id}>
        {frontOffice.name}
      </option>
    );
  });

  const frontOfficeName = frontOffices.filter(
    (nume) => nume.id === frontOfficesId
  );

  useEffect(() => {
    const pretFaraTva = (
      Number(valoareTotalaPieseFaraTva) + Number(valoareTotalaManoperaFaraTva)
    ).toFixed(2);
    setTotalContractFaraTVA(pretFaraTva);
    const pretCuTva = (pretFaraTva * (TAX_RATE + 1)).toFixed(2);
    setTotalContractCuTVA(pretCuTva);
    const tva = (pretCuTva - pretFaraTva).toFixed(2);
    setTvaDeviz(tva);

    // eslint-disable-next-line
  }, [valoareTotalaPieseFaraTva, valoareTotalaManoperaFaraTva]);

  useEffect(() => {
    switch (status) {
      case "inAsteptare":
        setType("CONTRACT DE REPARATII");
        break;
      case "inLucru":
        setType("CONTRACT DE REPARATII");
        break;
      case "reparat":
        setType("DEVIZ FINAL");
        break;
      case "predat":
        setType("DEVIZ FINAL");
        break;
      case "oferta":
        setType("OFERTA DE REPARATIE");
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [status]);

  const onPredarePieseVechiChanged = (e) =>
    setPredarePieseVechi(e.target.checked);
  const onGdprChanged = (e) => setGdpr(e.target.checked);
  const onAcceptPieseReconditionateChanged = (e) =>
    setAcceptPieseReconditionate(e.target.checked);

  const canSave = [userId, car, frontOfficesId].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (idDeviz) {
      if (canSave) {
        await updateDeviz({
          id: idDeviz,
          user: userId,
          car: car.id,
          frontOffice: frontOfficesId,
          numarDeviz: devizData.numarDeviz,
          tipDeviz: type,
          km: km,
          nivelDevizCarburant: nivelDevizCarburant,
          valEstimativaRep: valEstimativaRep,
          termenEstimativRep: termenEstimativRep,
          sesizariClient: sesizariClient,
          constatareService: constatareService,
          defecteConstatateSiNeremediate: defecteConstatateSiNeremediate,
          piese: listaPiese,
          valoareTotalaPieseFaraTva: valoareTotalaPieseFaraTva,
          manopera: listaManopere,
          valoareTotalaManoperaFaraTva: valoareTotalaManoperaFaraTva,
          predarePieseVechi: predarePieseVechi,
          gdpr: gdpr,
          acceptPieseReconditionate: acceptPieseReconditionate,
          status: status,
        });
        message.success("Devizul a fost salvat cu succes!");
      }
    } else {
      if (canSave) {
        await addNewDeviz({
          user: userId,
          car: car.id,
          frontOffice: frontOfficesId,
          numarDeviz: devizData.numarDeviz,
          tipDeviz: type,
          km: km,
          nivelDevizCarburant: nivelDevizCarburant,
          valEstimativaRep: valEstimativaRep,
          termenEstimativRep: termenEstimativRep,
          sesizariClient: sesizariClient,
          constatareService: constatareService,
          defecteConstatateSiNeremediate: defecteConstatateSiNeremediate,
          piese: listaPiese,
          valoareTotalaPieseFaraTva: valoareTotalaPieseFaraTva,
          manopera: listaManopere,
          valoareTotalaManoperaFaraTva: valoareTotalaManoperaFaraTva,
          predarePieseVechi: predarePieseVechi,
          gdpr: gdpr,
          acceptPieseReconditionate: acceptPieseReconditionate,
          status: status,
        });
        message.success("Devizul a fost salvat cu succes!");
        localStorage.removeItem("idCar");
      } else {
        message.error("Introduceti datele vehiculului!");
      }
    }
  };

  const content = (
    <>
      <div className={styles.invoiceLayout}>
        <form onSubmit={handleSubmit} className="mu-form">
          {/* <AddCar setOpen={setOpen} open={open} /> */}
          {/* nr deviz si tip de deviz */}
          <Container style={classes.headerContainer}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <TipDeviz type={type} setType={setType} />
              </Grid>

              <Grid item>
                DEVIZ NR.:
                <div
                  style={{
                    marginTop: "15px",
                    padding: "8px",
                    display: "inline-block",
                    outline: "0px solid transparent",
                  }}
                  //contentEditable="true"
                  onInput={(e) =>
                    setDevizData({
                      ...devizData,
                      numarDeviz: e.currentTarget.textContent,
                    })
                  }
                >
                  <span
                    style={{ width: "100px", color: "black", padding: "1px" }}
                    //contentEditable="false"
                  >
                    {" "}
                    {devizData.numarDeviz}
                  </span>
                  <br />
                </div>
                din
                <span
                  style={{ width: "100px", color: "black", padding: "1px" }}
                  //contentEditable="false"
                >
                  {" "}
                  {idDeviz
                    ? getFormattedDate(idDeviz.createdAt)
                    : getFormattedDate(new Date())}
                </span>
                <Grid
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      marginRight: "7px",
                    }}
                  >
                    Stare deviz:
                  </div>
                  <select value={status} onChange={onStatusChanged}>
                    <option value="inAsteptare">In asteptare</option>
                    <option value="inLucru">In lucru</option>
                    <option value="reparat">Vehicul gata de predat</option>
                    <option value="predat">Vehicul predat</option>
                    <option value="oferta">Oferta</option>
                  </select>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Divider />

          {/* selectare vehicul si client */}
          <Container>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <VehClient
                userId={userId}
                car={car}
                setCar={setCar}
                client={client}
                setClient={setClient}
                km={km}
                setKm={setKm}
                nivelDevizCarburant={nivelDevizCarburant}
                setNivelDevizCarburant={setNivelDevizCarburant}
                carId={idDeviz ? idDeviz?.car : null}
              />
            </Grid>
          </Container>

          <Divider />

          {/* date deviz/ solicitare client si constatari */}
          <Container>
            <ConstatariDeviz
              valEstimativaRep={valEstimativaRep}
              setValEstimativaRep={setValEstimativaRep}
              termenEstimativRep={termenEstimativRep}
              setTermenEstimativRep={setTermenEstimativRep}
              sesizariClient={sesizariClient}
              setSesizariClient={setSesizariClient}
              constatareService={constatareService}
              setConstatareService={setConstatareService}
              defecteConstatateSiNeremediate={defecteConstatateSiNeremediate}
              setDefecteConstatateSiNeremediate={
                setDefecteConstatateSiNeremediate
              }
            />
          </Container>

          {/* adaugare piese */}
          <Container>
            <PieseDeviz
              piesa={piesa}
              setPiesa={setPiesa}
              listaPiese={listaPiese}
              setListaPiese={setListaPiese}
              setValoareTotalaPieseFaraTva={setValoareTotalaPieseFaraTva}
              TAX_RATE={TAX_RATE}
              furnizoriId={furnizoriId}
              setFurnizoriId={setFurnizoriId}
              furnizori={furnizori}
            />
          </Container>

          {/* adaugare manopera */}
          <Container>
            <ManoperaDeviz
              manopera={manopera}
              setManopera={setManopera}
              listaManopere={listaManopere}
              setListaManopere={setListaManopere}
              setValoareTotalaManoperaFaraTva={setValoareTotalaManoperaFaraTva}
              TAX_RATE={TAX_RATE}
              mechanicsId={mechanicsId}
              setMechanicsId={setMechanicsId}
              mechanics={mechanics}
            />
          </Container>

          <Container>
            <Container>
              <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid container display="flex" flexDirection="column">
                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <strong
                      style={{
                        marginRight: "30px",
                      }}
                    >
                      Total contract (fara TVA)
                    </strong>
                    {totalContractFaraTVA} RON
                  </div>
                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <strong
                      style={{
                        marginRight: "172px",
                      }}
                    >
                      TVA
                    </strong>{" "}
                    {tvaDeviz} RON
                  </div>
                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <strong
                      style={{
                        marginRight: "40px",
                      }}
                    >
                      Total contract (cu TVA)
                    </strong>
                    {totalContractCuTVA} RON
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Container>

          <Divider />

          <Container>
            <Container>
              <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid>
                  <div>
                    <input
                      type="checkbox"
                      name="predarePieseVechi" //IMPORTANT
                      label="predarePieseVechi"
                      checked={predarePieseVechi}
                      onChange={onPredarePieseVechiChanged}
                    />{" "}
                    Se solicita piesele si consumabilele inlocuite.
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="gdpr" //IMPORTANT
                      label="gdpr"
                      checked={gdpr}
                      onChange={onGdprChanged}
                    />{" "}
                    Sunt de acord cu prelucrarea datelor cu caracter personal
                    conform Legii 677/2001.
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="acceptPieseReconditionate" //IMPORTANT
                      label="acceptPieseReconditionate"
                      checked={acceptPieseReconditionate}
                      onChange={onAcceptPieseReconditionateChanged}
                    />{" "}
                    Accept / solicit utilizarea pieselor reconditionate,
                    reparate, reutilizabile.
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Container>

          <Container>
            <Container>
              <Grid style={{ width: "100%" }}>
                Receptioner:{" "}
                <select
                  value={frontOfficesId}
                  onChange={onFrontOfficesIdChanged}
                >
                  {optionsFrontOffices}
                </select>
              </Grid>
            </Container>
          </Container>
          {idDeviz && (
            <>
              <Container>
                <ListaInspectiiFinale idDeviz={idDeviz} />
              </Container>
            </>
          )}

          <Divider />

          <Grid container justifyContent="center">
            <Button
              variant="contained"
              style={{ justifyContentContent: "center" }}
              type="submit"
              color="primary"
              size="large"
            >
              Salveaza Deviz
            </Button>
            {idDeviz && (
              <Print
                numarDeviz={devizData.numarDeviz}
                tipDeviz={type}
                data={getFormattedDate(idDeviz?.createdAt)}
                car={car}
                km={km}
                nivelDevizCarburant={nivelDevizCarburant}
                valEstimativaRep={valEstimativaRep}
                termenEstimativRep={termenEstimativRep}
                sesizariClient={sesizariClient}
                constatareService={constatareService}
                defecteConstatateSiNeremediate={defecteConstatateSiNeremediate}
                listaPiese={listaPiese}
                valoareTotalaPieseFaraTva={valoareTotalaPieseFaraTva}
                listaManopere={listaManopere}
                valoareTotalaManoperaFaraTva={valoareTotalaManoperaFaraTva}
                totalContractFaraTVA={totalContractFaraTVA}
                tvaDeviz={tvaDeviz}
                totalContractCuTVA={totalContractCuTVA}
                predarePieseVechi={predarePieseVechi}
                gdpr={gdpr}
                acceptPieseReconditionate={acceptPieseReconditionate}
                frontOfficeName={frontOfficeName}
              />
            )}
          </Grid>
        </form>
      </div>
    </>
  );

  return content;
}

export default DevizNou;
