import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useGetUserQuery, useUpdateUserMutation } from "../user/usersApiSlice";
import { Button, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { SketchPicker } from "react-color";

const tagInitial = {
  color: "#37d67a",
  name: "",
};

const SetariProg = () => {
  const { id } = useAuth();
  const { data: user, isSuccess } = useGetUserQuery(id);

  const [updateUser] = useUpdateUserMutation();

  const [editModalVisibilty, setEditModalVisibilty] = useState(false);

  const [tag, setTag] = useState(tagInitial);
  const [tagEdit, setTagEdit] = useState();
  const [tagsList, settagsList] = useState(user?.user.tagProg);

  const [show, setShow] = useState(false);

  const onTagChangedEdit = (e) => {
    const { name, value } = e.target;
    setTagEdit({
      ...tagEdit,
      [name]: value,
    });
  };

  const handleClickAddTag = (e) => {
    e.preventDefault();

    settagsList([
      ...tagsList,
      {
        id: Math.floor(Math.random() * 10000000000001),
        color: tag.color,
        name: tag.name,
      },
    ]);
    //message.success("Piesa a fost adaugata!");
    setTag(tagInitial);
  };

  const handleRemoveField = (id) => {
    const newList = tagsList.filter((tag) => tag.id !== id);
    settagsList(newList);
  };

  const handleClickEditTag = (tagForEdit) => {
    const editTag = tagsList.map((obj) => {
      if (obj.id === tagForEdit.id) {
        let color = "";
        let name = "";
        if (tagEdit.color === undefined) {
          color = obj.color;
        } else {
          color = tagEdit.color;
        }
        if (tagEdit.name === undefined) {
          name = obj.name;
        } else {
          name = tagEdit.name;
        }
        return {
          ...obj,
          color: color,
          name: name,
        };
      }
      return obj;
    });
    settagsList(editTag);
  };

  function toggleShow() {
    setShow(!show);
  }

  const onSaveUserClicked = async (e) => {
    await updateUser({
      id: user.user._id,
      username: user.user.username,
      companyName: user.user.companyName,
      address: user.user.address,
      cif: user.user.cif,
      regCom: user.user.regCom,
      tel: user.user.tel,
      roles: user.user.roles,
      active: user.user.active,
      tagProg: tagsList,
    });
  };

  let content;

  if (isSuccess) {
    content = (
      <>
        <SettingOutlined
          style={{ fontSize: "25px" }}
          onClick={() => setEditModalVisibilty(true)}
        />

        {editModalVisibilty && (
          <Modal
            onCancel={() => {
              setEditModalVisibilty(false);
            }}
            open={editModalVisibilty}
            centered
            title={`Setari programari`}
            footer={false}
          >
            <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
              <div>Nume operatiune:</div>
              <div
                onClick={toggleShow}
                style={{
                  backgroundColor: `${tag.color}`,
                  width: 25,
                  height: 25,
                  border: "1px solid black",
                  cursor: "pointer",
                }}
              ></div>
              {show && (
                <Modal
                  onCancel={() => {
                    setShow(!show);
                  }}
                  open={show}
                  centered
                  onOk={() => setShow(!show)}
                  title={`Alege o culoare`}
                  footer={false}
                  width={265}
                >
                  <SketchPicker
                    color={tag.color}
                    onChange={(color) => {
                      setTag({
                        ...tag,
                        color: color.hex,
                      });
                    }}
                  />
                </Modal>
              )}
              <div>
                <input
                  //className={styles.noOutlineForm}
                  type="text"
                  autoComplete="off"
                  name="name" //IMPORTANT
                  label="Name"
                  value={tag.name}
                  onChange={(name) => {
                    setTag({
                      ...tag,
                      name: name.target.value,
                    });
                  }}
                />{" "}
              </div>
              <button onClick={handleClickAddTag}>Adauga</button>
            </div>
            <div>
              {tagsList?.map((tag, idx) => (
                <div key={idx}>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: `${tag.color}`,
                        width: 25,
                        height: 25,
                        border: "1px solid black",
                      }}
                    ></div>
                    <div>
                      <input
                        type="text"
                        autoComplete="off"
                        name="name" //IMPORTANT
                        label="Name"
                        defaultValue={tag.name}
                        onChange={onTagChangedEdit}
                      />{" "}
                    </div>

                    <EditOutlined
                      style={{
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        handleClickEditTag(tag);
                      }}
                    />
                    <DeleteOutlined
                      style={{
                        fontSize: "20px",
                      }}
                      onClick={() => handleRemoveField(tag.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  onSaveUserClicked();
                  setEditModalVisibilty(false);
                }}
              >
                {`Salveaza setarile`}
              </Button>
            </div>
          </Modal>
        )}
      </>
    );
  }

  return content;
};
export default SetariProg;
