import React, { useEffect, useState } from "react";
import { useGetUserQuery, useUpdateUserMutation } from "./usersApiSlice";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../hooks/useAuth";
import { Button, Modal, Form, Input } from "antd";

const USER_REGEX = /^[A-z0-9]{3,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;
const CIF_REGEX = /^[A-z0-9]{9,12}$/;
const REGCOM_REGEX = /^[A-z0-9/]/;
const TEL_REGEX = /^[0-9]{10}$/;

const UsersList = () => {
  const { id } = useAuth();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUserQuery(id, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [editModalVisibilty, setEditModalVisibilty] = useState(false);

  const [username, setUsername] = useState(user.user.username);
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [companyName, setCompanyName] = useState(user.user.companyName);
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [address, setAddress] = useState(user.user.address);
  const [validAddress, setValidAddress] = useState(false);
  const [cif, setCif] = useState(user.user.cif);
  const [validCif, setValidCif] = useState(false);
  const [regCom, setRegCom] = useState(user.user.regCom);
  const [validRegCom, setValidRegCom] = useState(false);
  const [tel, setTel] = useState(user.user.tel);
  const [validTel, setValidTel] = useState(false);

  const [roles] = useState(user.user.roles);
  const [active] = useState(user.user.active);

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setValidCompanyName(companyName);
  }, [companyName]);

  useEffect(() => {
    setValidAddress(address);
  }, [address]);

  useEffect(() => {
    setValidCif(CIF_REGEX.test(cif));
  }, [cif]);

  useEffect(() => {
    setValidRegCom(REGCOM_REGEX.test(regCom));
  }, [regCom]);

  useEffect(() => {
    setValidTel(TEL_REGEX.test(tel));
  }, [tel]);

  const onUsernameChanged = (e) => setUsername(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const onCompanyNameChanged = (e) => setCompanyName(e.target.value);
  const onAddressChanged = (e) => setAddress(e.target.value);
  const onCifChanged = (e) => setCif(e.target.value);
  const onRegComChanged = (e) => setRegCom(e.target.value);
  const onTelChanged = (e) => setTel(e.target.value);

  const [updateUser] = useUpdateUserMutation();

  let content;

  const getUser = () => {
    if (isLoading) content = <PulseLoader color={"#FFF"} />;

    if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>;
    }

    if (isSuccess) {
      setUsername(user.user.username);
      setCompanyName(user.user.companyName);
      setAddress(user.user.address);
      setCif(user.user.cif);
      setRegCom(user.user.regCom);
      setTel(user.user.tel);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [user]);

  const onSaveUserClicked = async (e) => {
    if (password) {
      await updateUser({
        id: user.user._id,
        username,
        password,
        companyName,
        address,
        cif,
        regCom,
        tel,
        roles,
        active,
        tagProg: user.user.tagProg,
      });
    } else {
      await updateUser({
        id: user.user._id,
        username,
        companyName,
        address,
        cif,
        regCom,
        tel,
        roles,
        active,
        tagProg: user.user.tagProg,
      });
    }
  };

  let canSave;
  if (password) {
    canSave =
      [
        // roles.length,
        validUsername,
        validPassword,
        validCompanyName,
        validAddress,
        validCif,
        validRegCom,
        validTel,
      ].every(Boolean) && !isLoading;
  } else {
    canSave =
      [
        // roles.length,
        validUsername,
        validCompanyName,
        validAddress,
        validCif,
        validRegCom,
        validTel,
      ].every(Boolean) && !isLoading;
  }

  const validUserClass = !validUsername ? "form__input--incomplete" : "";
  const validPwdClass =
    password && !validPassword ? "form__input--incomplete" : "";

  const validCompanyNameClass = !validCompanyName
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !validAddress ? "form__input--incomplete" : "";
  const validCifClass = !validCif ? "form__input--incomplete" : "";
  const validRegComClass = !validRegCom ? "form__input--incomplete" : "";
  const validTelClass = !validTel ? "form__input--incomplete" : "";

  if (isSuccess) {
    content = (
      <>
        <div className="d-flex justify-content-between">
          <h3>Setari Profil</h3>
          <Button
            style={{ width: 155 }}
            type="primary"
            onClick={() => setEditModalVisibilty(true)}
          >
            Modifica datele
          </Button>
        </div>
        <div>&nbsp;</div>
        <h5>{companyName}</h5>
        <h5>{address}</h5>
        <h5>{cif}</h5>
        <h5>{regCom}</h5>
        <h5>{tel}</h5>

        {editModalVisibilty && (
          <Modal
            onCancel={() => {
              setEditModalVisibilty(false);
            }}
            open={editModalVisibilty}
            centered
            title={`Modifica datele companiei`}
            footer={false}
          >
            <Form className="form" onSubmit={(e) => e.preventDefault()}>
              <label className="form__label" htmlFor="username">
                Nume utilizator: <span className="nowrap">[3-20 litere]</span>
              </label>
              <Input
                className={`${validUserClass}`}
                id="username"
                name="username"
                type="text"
                autoComplete="off"
                value={username}
                onChange={onUsernameChanged}
              />

              <label className="form__label" htmlFor="password">
                Parola: <span className="nowrap">[gol = nicio schimbare]</span>{" "}
                <span className="nowrap">[4-12 caractere inclusiv !@#$%]</span>
              </label>
              <Input
                className={`form__input ${validPwdClass}`}
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={onPasswordChanged}
              />

              <label className="form__label" htmlFor="companyName">
                Numele companiei: <span className="nowrap">[3-20 litere]</span>
              </label>
              <Input
                className={`form__input ${validCompanyNameClass}`}
                id="companyName"
                name="companyName"
                type="text"
                autoComplete="off"
                value={companyName}
                onChange={onCompanyNameChanged}
              />

              <label className="form__label" htmlFor="address">
                Adresa: <span className="nowrap"></span>
              </label>
              <Input
                className={`form__input ${validAddressClass}`}
                id="address"
                name="address"
                type="text"
                autoComplete="off"
                value={address}
                onChange={onAddressChanged}
              />

              <label className="form__label" htmlFor="cif">
                CIF: <span className="nowrap"></span>
              </label>
              <Input
                className={`form__input ${validCifClass}`}
                id="cif"
                name="cif"
                type="text"
                autoComplete="off"
                value={cif}
                onChange={onCifChanged}
              />

              <label className="form__label" htmlFor="regCom">
                Reg. Com.: <span className="nowrap"></span>
              </label>
              <Input
                className={`form__input ${validRegComClass}`}
                id="regCom"
                name="regCom"
                type="text"
                autoComplete="off"
                value={regCom}
                onChange={onRegComChanged}
              />

              <label className="form__label" htmlFor="tel">
                Telefon: <span className="nowrap"></span>
              </label>
              <Input
                className={`form__input ${validTelClass}`}
                id="tel"
                name="tel"
                type="text"
                autoComplete="off"
                value={tel}
                onChange={onTelChanged}
              />

              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  onClick={() => {
                    onSaveUserClicked();
                    setEditModalVisibilty(false);
                  }}
                  disabled={!canSave}
                >
                  {`Salveaza modificarile`}
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }

  return content;
};
export default UsersList;
