import React from "react";
import "./App.css";
import DefaultLayout from "./components/DefaultLayout";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./features/auth/Login";
import Register from "./features/auth/Register";
import Homepage from "./features/auth/Homepage";
import DevizeList from "./features/devize/DevizeList";
import DevizNou from "./features/devize/DevizNou";
import EditDeviz from "./features/devize/EditDeviz";
import Clienti from "./pages/Clienti";
import Service from "./pages/Service";
import ManoperaMecanici from "./features/service/ManoperaMecanici";
import Programari from "./pages/Programari";
import Rapoarte from "./pages/Rapoarte";
import Setari from "./pages/Setari";

import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";

function App() {
  useTitle("Devize auto");

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />

          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
            >
              <Route element={<Prefetch />}>
                <Route path="dash" element={<DefaultLayout />}>
                  <Route index element={<Homepage />} />

                  <Route path="devize">
                    <Route index element={<DevizeList />} />
                    <Route path=":idDeviz" element={<EditDeviz />} />
                    <Route path="new" element={<DevizNou />} />
                  </Route>
                  <Route path="clienti">
                    <Route index element={<Clienti />} />
                  </Route>
                  <Route path="service">
                    <Route index element={<Service />} />
                    <Route path="mecanici" element={<ManoperaMecanici />} />
                  </Route>
                  <Route path="programari">
                    <Route index element={<Programari />} />
                  </Route>
                  <Route path="rapoarte">
                    <Route index element={<Rapoarte />} />
                  </Route>
                  <Route path="users">
                    <Route index element={<Setari />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* End Protected Routes */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
