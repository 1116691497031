import React, { useState, useEffect, useReducer, useMemo } from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";
import {
  useGetProgramsQuery,
  useAddNewProgramMutation,
  useUpdateProgramMutation,
} from "./programsApiSlice";
import useAuth from "../../../hooks/useAuth";

function savedEventsReducer(state, { type, payload }) {
  switch (type) {
    case "push":
      return [...state, payload];
    case "update":
      return state.map((evt) => (evt.id === payload.id ? payload : evt));
    case "delete":
      return state.filter((evt) => evt.id !== payload.id);
    default:
      throw new Error();
  }
}

export default function ContextWrapper(props) {
  const [addNewProgram] = useAddNewProgramMutation();
  const [updateProgram] = useUpdateProgramMutation();

  const { id } = useAuth();

  const { data: programs } = useGetProgramsQuery(id, {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [idProgram, setIdProgram] = useState();
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [labels, setLabels] = useState([]);
  const [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );

  function initEvents() {
    //const storageEvents = localStorage.getItem("savedEvents");

    if (programs !== undefined) {
      var result = Object.keys(programs?.entities).map(
        (key) => programs.entities[key]
      );
      setIdProgram(result[0].id);
      result = result[0].programari;
    } else {
      addNewProgram({
        user: id,
        programari: [],
      });
    }

    //console.log(storageEvents);
    const parsedEvents = result ? result : [];
    // const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
    return parsedEvents;
  }

  const filteredEvents = useMemo(() => {
    return savedEvents.filter((evt) =>
      labels
        .filter((lbl) => lbl.checked)
        .map((lbl) => lbl.label)
        .includes(evt.label)
    );
  }, [savedEvents, labels]);

  useEffect(() => {
    if (idProgram !== undefined) {
      updateProgram({
        id: idProgram,
        user: id,
        programari: savedEvents,
      });
    }
    //localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
  }, [savedEvents, id, idProgram, updateProgram]);

  useEffect(() => {
    setLabels((prevLabels) => {
      return [...new Set(savedEvents.map((evt) => evt.label))].map((label) => {
        const currentLabel = prevLabels.find((lbl) => lbl.label === label);
        return {
          label,
          checked: currentLabel ? currentLabel.checked : true,
        };
      });
    });
  }, [savedEvents]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  function updateLabel(label) {
    setLabels(labels.map((lbl) => (lbl.label === label.label ? label : lbl)));
  }

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        setLabels,
        labels,
        updateLabel,
        filteredEvents,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
