import useAuth from "../../hooks/useAuth";
import DevizNouForm from "./devizNou/DevizNouForm";
import { useGetMechanicsQuery } from "../mechanics/mechanicsApiSlice";
import { useGetFrontOfficesQuery } from "../frontOffice/frontOfficesApiSlice";
import { useGetFurnizoriQuery } from "../furnizori/furnizoriApiSlice";

function DevizNou() {
  const { id } = useAuth();

  const { mechanics } = useGetMechanicsQuery(id, {
    selectFromResult: ({ data }) => ({
      mechanics: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });
  const resultsMechanics = mechanics.filter((element) => {
    return element.employee === true;
  });

  const { frontOffices } = useGetFrontOfficesQuery(id, {
    selectFromResult: ({ data }) => ({
      frontOffices: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });
  const resultsFrontOffices = frontOffices.filter((element) => {
    return element.employee === true;
  });

  const { furnizori } = useGetFurnizoriQuery(id, {
    selectFromResult: ({ data }) => ({
      furnizori: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });
  const resultsFurnizori = furnizori.filter((element) => {
    return element.colaborare === true;
  });

  const content = (
    <DevizNouForm
      userId={id}
      mechanics={resultsMechanics}
      frontOffices={resultsFrontOffices}
      furnizori={resultsFurnizori}
      idDeviz={null}
    />
  );

  return content;
}

export default DevizNou;
