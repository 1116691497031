import React from "react";
import { Button } from "@mui/material";

const TipDeviz = ({ type }) => {
  return (
    <div>
      <Button style={{ lineSpacing: 1, fontSize: 30, fontWeight: 700 }}>
        {type}
      </Button>
    </div>
  );
};

export default TipDeviz;
