import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const devizeAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = devizeAdapter.getInitialState();

export const devizeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDevize: builder.query({
      query: (id) => ({
        url: `/devize/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedDevize = responseData.map((deviz) => {
          deviz.id = deviz._id;
          return deviz;
        });
        return devizeAdapter.setAll(initialState, loadedDevize);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Deviz", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Deviz", id })),
          ];
        } else return [{ type: "Deviz", id: "LIST" }];
      },
    }),
    addNewDeviz: builder.mutation({
      query: (initialDeviz) => ({
        url: "/devize",
        method: "POST",
        body: {
          ...initialDeviz,
        },
      }),
      invalidatesTags: [{ type: "Deviz", id: "LIST" }],
    }),
    updateDeviz: builder.mutation({
      query: (initialDeviz) => ({
        url: "/devize",
        method: "PATCH",
        body: {
          ...initialDeviz,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Deviz", id: arg.id }],
    }),
    deleteDeviz: builder.mutation({
      query: ({ id }) => ({
        url: `/devize`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Deviz", id: arg.id }],
    }),
  }),
});

export const {
  useGetDevizeQuery,
  useAddNewDevizMutation,
  useUpdateDevizMutation,
  useDeleteDevizMutation,
} = devizeApiSlice;

// returns the query result object
export const selectDevizeResult = devizeApiSlice.endpoints.getDevize.select();

// creates memoized selector
const selectDevizeData = createSelector(
  selectDevizeResult,
  (devizeResult) => devizeResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllDevize,
  selectById: selectDevizById,
  selectIds: selectDevizIds,
  // Pass in a selector that returns the devize slice of state
} = devizeAdapter.getSelectors(
  (state) => selectDevizeData(state) ?? initialState
);
