import { store } from "../../app/store";
import { mechanicsApiSlice } from "../mechanics/mechanicsApiSlice";
import { frontOfficesApiSlice } from "../frontOffice/frontOfficesApiSlice";
import { clientsApiSlice } from "../clients/clientsApiSlice";
import { carsApiSlice } from "../cars/carsApiSlice";
import { usersApiSlice } from "../user/usersApiSlice";
import { furnizoriApiSlice } from "../furnizori/furnizoriApiSlice";
import { devizeApiSlice } from "../devize/devizeApiSlice";
import { programsApiSlice } from "../programari/context/programsApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Prefetch = () => {
  const { id } = useAuth();

  useEffect(() => {
    store.dispatch(
      usersApiSlice.util.prefetch("getUser", id, {
        force: true,
      })
    );
    store.dispatch(
      mechanicsApiSlice.util.prefetch("getMechanics", id, {
        force: true,
      })
    );
    store.dispatch(
      furnizoriApiSlice.util.prefetch("getFurnizori", id, {
        force: true,
      })
    );
    store.dispatch(
      frontOfficesApiSlice.util.prefetch("getFrontOffices", id, {
        force: true,
      })
    );
    store.dispatch(
      clientsApiSlice.util.prefetch("getClients", id, {
        force: true,
      })
    );
    store.dispatch(
      carsApiSlice.util.prefetch("getCars", id, {
        force: true,
      })
    );
    store.dispatch(
      devizeApiSlice.util.prefetch("getDevize", id, {
        force: true,
      })
    );
    store.dispatch(
      programsApiSlice.util.prefetch("getPrograms", id, {
        force: true,
      })
    );
  }, [id]);

  return <Outlet />;
};
export default Prefetch;
