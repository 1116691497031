import React, { useEffect, useState } from "react";
import {
  useGetFrontOfficesQuery,
  useUpdateFrontOfficeMutation,
  useDeleteFrontOfficeMutation,
  useAddNewFrontOfficeMutation,
} from "./frontOfficesApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Checkbox, Input, message, Modal, Table } from "antd";

function FrontOfficesList() {
  const [frontOfficesData, setFrontOfficesData] = useState([]);
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const { id } = useAuth();

  const {
    data: frontOffices,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetFrontOfficesQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [deleteFrontOffice] = useDeleteFrontOfficeMutation();

  const [updateFrontOffice] = useUpdateFrontOfficeMutation();

  const onDeleteFrontOfficeClicked = async (record) => {
    try {
      message.success("Receptionerul a fost sters cu succes");
      await deleteFrontOffice({ id: record }).unwrap();
    } catch (err) {
      message.error(
        "Nu se poate sterge acest receptioner, exista informatii in baza de date legate de el"
      );
    }
  };

  const [addNewFrontOffice] = useAddNewFrontOfficeMutation();

  const getFrontOffices = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(frontOffices?.entities).map(
        (key) => frontOffices.entities[key]
      );
      setFrontOfficesData(result);
    }
  };

  useEffect(() => {
    getFrontOffices();
    // eslint-disable-next-line
  }, [frontOffices]);

  //_________________________________________________________________

  const date = (timestamp) => new Date(timestamp).toLocaleString();

  //__________________________________________________________________

  const columns = [
    {
      title: "Nume receptioner",
      dataIndex: "name",
    },
    {
      title: "Stare angajare",
      dataIndex: "employee",
      render: (employee) => (
        <div className="d-flex">
          {employee ? (
            <span>Angajat</span>
          ) : (
            <span style={{ color: "red" }}>Nu mai lucreaza</span>
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.employee - b.employee,
      },
      sortOrder: "descend",
      // defaultSortOrder: "descend",
    },
    {
      title: "Data adaugarii",
      dataIndex: "createdAt",
      render: (createdAt) => <div>{date(createdAt)}</div>,
    },
    {
      title: "Data modificarii",
      dataIndex: "updatedAt",
      render: (updatedAt) => <div>{date(updatedAt)}</div>,
    },
    {
      title: "Modificari",
      dataIndex: "id",
      render: (id, record) => (
        <div className="d-flex">
          <EditOutlined
            className="mx-2"
            onClick={() => {
              setEditingItem(record);
              setAddEditModalVisibilty(true);
            }}
          />
          <DeleteOutlined
            className="mx-2"
            onClick={() => onDeleteFrontOfficeClicked(id)}
          />
        </div>
      ),
    },
  ];

  const onFinish = async (values) => {
    if (editingItem === null) {
      const canSave = [values.name, id].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await addNewFrontOffice({ user: id, name: values.name }).unwrap();
          /// se pune unrap ca este in interiorul try catch block
          message.success("Receptionerul a fost adaugat cu succes");
          setAddEditModalVisibilty(false);
        } catch (err) {
          message.error("Nu se poate salva acest receptioner", err);
        }
      } else {
        message.error("Introdu un nume de receptioner");
      }
    } else {
      const canSave = [values.name, values.user].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await updateFrontOffice({
            id: values.id,
            user: values.user,
            name: values.name,
            employee: values.employee,
          }).unwrap();

          message.success("Datele receptionerului modificate cu succes");
          setAddEditModalVisibilty(false);
          setEditingItem(null);
        } catch (err) {
          message.error("Nu se poate modifica acest receptioner");
        }
      } else {
        message.error("Introdu un nume de receptioner");
      }
    }
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      <div className="d-flex justify-content-between">
        <h3>Receptioneri</h3>
        <Button
          style={{ width: 155 }}
          type="primary"
          onClick={() => setAddEditModalVisibilty(true)}
        >
          Adauga Receptioner
        </Button>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      {isSuccess && (
        <Table
          columns={columns}
          dataSource={frontOfficesData}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ["5", "10"],
            showSizeChanger: true,
          }}
        />
      )}

      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null);
            setAddEditModalVisibilty(false);
          }}
          open={addEditModalVisibilty}
          centered
          title={`${
            editingItem !== null ? "Modifica receptioner" : "Adauga receptioner"
          }`}
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item name="id" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="user" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>

            {editingItem && (
              <Form.Item
                name="employee"
                label="Stare angajare"
                valuePropName="checked"
              >
                <Checkbox>Angajat?</Checkbox>
              </Form.Item>
            )}

            <div className="d-flex justify-content-end">
              <Button htmlType="submit" type="primary">
                {`${editingItem !== null ? "Salveaza" : "Adauga"}`}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );

  return content;
}

export default FrontOfficesList;
