import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import "./print.css";
import useAuth from "../../../hooks/useAuth";
import { useGetUserQuery } from "../../user/usersApiSlice";
import { useGetClientsQuery } from "../../clients/clientsApiSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { current } from "@reduxjs/toolkit";

const Print = ({
  numarDeviz,
  tipDeviz,
  data,
  car,
  km,
  nivelDevizCarburant,
  valEstimativaRep,
  termenEstimativRep,
  sesizariClient,
  constatareService,
  defecteConstatateSiNeremediate,
  listaPiese,
  valoareTotalaPieseFaraTva,
  listaManopere,
  valoareTotalaManoperaFaraTva,
  totalContractFaraTVA,
  tvaDeviz,
  totalContractCuTVA,
  predarePieseVechi,
  gdpr,
  acceptPieseReconditionate,
  frontOfficeName,
}) => {
  const componentRef = useRef();
  const printDiv = useRef();

  const { id } = useAuth();

  const { data: user } = useGetUserQuery(id);

  const { clients } = useGetClientsQuery(id, {
    selectFromResult: ({ data }) => ({
      clients: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const clientCar = clients?.find((e) => e.id === car?.client);

  const [printDevizModalVisibility, setPrintDevizModalVisibilty] =
    useState(false);

  const [inaltime, setInaltime] = useState();

  const handleClickOpen = () => {
    setPrintDevizModalVisibilty(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const gigi = printDiv.current ? printDiv.current.clientHeight + 4 : 0;

    const inaltime = gigi / 1123;
    setInaltime(inaltime);
  }, [printDevizModalVisibility]);

  function getFormattedDate(dateString) {
    const currentDate = new Date(dateString);
    let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    let day = ("0" + currentDate.getDate()).slice(-2);
    let year = currentDate.getFullYear();
    let hours = ("0" + currentDate.getHours()).slice(-2);
    let minutes = ("0" + currentDate.getMinutes()).slice(-2);

    return `${day}/${month}/${year}${" "}${hours}:${minutes}`;
  }

  return (
    <>
      <PrinterOutlined
        style={{ fontSize: "40px", color: "#08c", marginLeft: "10px" }}
        onClick={handleClickOpen}
      />

      {printDevizModalVisibility && (
        <Modal
          onCancel={() => {
            setPrintDevizModalVisibilty(false);
          }}
          open={printDevizModalVisibility}
          title="Detalii deviz"
          footer={false}
          width={840}
        >
          <div className="deviz-button d-flex justify-content-center">
            <Button type="primary" onClick={handlePrint}>
              Printeaza Deviz
            </Button>
          </div>
          <div>
            <div ref={componentRef} style={{ margin: "0", padding: "0" }}>
              <div id="pageCounter">
                <span></span>
                {inaltime > 1 && <span></span>}
                {inaltime > 2 && <span></span>}
                <span></span>
              </div>
              <div id="pageNumbers">
                <div className="deviz-model">
                  <div ref={printDiv} className="interior">
                    <div className="body">
                      <div className="deviz-firma">
                        <div>
                          <h6>{user.user.companyName}</h6>
                          <div className="deviz-mic">
                            <p>{user.user.address}, </p>
                            <p>{user.user.cif}</p>
                            <p>{user.user.regCom}</p>
                            <p>{user.user.tel}</p>
                          </div>
                        </div>
                        <div>
                          <p></p>
                        </div>
                      </div>
                      <div className="deviz-titlu">
                        {tipDeviz} NR. {numarDeviz} din {data}
                      </div>
                      {/* selectare vehicul si client */}
                      Contract reparatie
                      <div className="deviz-date">
                        <div
                          className="container"
                          style={{ width: "40%", marginRight: "3px" }}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Marca: {car.marca} Model: {car.model}
                          </div>
                          <div className="deviz-mic">
                            <div>Nr. inmatriculare: {car.nrinmatriculare}</div>
                            <div>Serie sasiu(VIN): {car.vin}</div>
                            <div>
                              kW: {car.kw} cmc:{car.capacitatecil} Data
                              fabricatie: {car.fabricatie}
                            </div>
                            <div>
                              KM: {km} Nivel carburant: {nivelDevizCarburant}
                            </div>
                          </div>
                        </div>
                        <div className="container" style={{ width: "60%" }}>
                          <div style={{ fontWeight: "bold" }}>
                            Client/Utilizator: {clientCar.name}
                          </div>
                          <div className="deviz-mic">
                            <div>
                              Adresa: {clientCar.adresa}, {clientCar.localitate}
                              , {clientCar.judet}
                            </div>
                            <div>CUI/CNP: {clientCar.cuicnp}</div>
                            <div>Contact: {clientCar.contact}</div>
                            <div>Telefon: {clientCar.telefon}</div>
                          </div>
                        </div>
                      </div>
                      {/* estimari si sesizari client */}
                      <div className="deviz-date">
                        <div style={{ width: "25%", marginRight: "3px" }}>
                          Estimari deviz:
                        </div>
                        <div style={{ width: "75%" }}>Sesizari client:</div>
                      </div>
                      <div className="deviz-date">
                        <div
                          className="container"
                          style={{ width: "25%", marginRight: "3px" }}
                        >
                          <div>
                            <div className="deviz-mic">
                              <div>Valoare estimativa reparatie:</div>
                              <div>{valEstimativaRep} RON</div>
                              <div>Termen estimativ executie:</div>
                              <div>
                                {termenEstimativRep.data.slice(-2)}/
                                {termenEstimativRep.data.slice(5, 7)}/
                                {termenEstimativRep.data.slice(0, 4)} ora:{" "}
                                {termenEstimativRep.ora}:
                                {termenEstimativRep.minutul}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="container" style={{ width: "75%" }}>
                          <div className="deviz-mic2">{sesizariClient}</div>
                        </div>
                      </div>
                      {/* constatari service */}
                      <div className="deviz-date">
                        <div style={{ width: "65%", marginRight: "3px" }}>
                          Constatari service:
                        </div>
                        <div style={{ width: "35%" }}>
                          Defecte constatate si neremediate:
                        </div>
                      </div>
                      <div className="deviz-date">
                        <div
                          className="container"
                          style={{
                            width: "65%",
                            minHeight: "70px",
                            marginRight: "3px",
                          }}
                        >
                          <div className="deviz-mic2">{constatareService}</div>
                        </div>

                        <div className="container" style={{ width: "35%" }}>
                          <div className="deviz-mic2">
                            {defecteConstatateSiNeremediate}
                          </div>
                        </div>
                      </div>
                      {/* piese */}
                      Piese de schimb utilizate:
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          size="small"
                          aria-label="spanning table"
                        >
                          <TableHead>
                            <TableRow size="small">
                              <TableCell>Nr.Crt</TableCell>
                              <TableCell>Cod</TableCell>
                              <TableCell>Descriere</TableCell>
                              <TableCell align="center">Cant.</TableCell>
                              <TableCell align="center">U.M.</TableCell>
                              <TableCell align="right">PU fara TVA</TableCell>
                              <TableCell align="right">Val. fara TVA</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody size="small">
                            {listaPiese?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {row.codOE} - {row.cod}
                                </TableCell>
                                <TableCell>{row.descriere}</TableCell>
                                <TableCell align="center">
                                  {row.cantitate}
                                </TableCell>
                                <TableCell align="center">{row.um}</TableCell>
                                <TableCell align="right">
                                  {row.pretUnitarFaraTva}
                                </TableCell>
                                <TableCell align="right">
                                  {row.pretFaraTva}
                                </TableCell>
                              </TableRow>
                            ))}

                            <TableRow size="small">
                              <TableCell colSpan={2} />
                              <TableCell colSpan={4}>
                                Total valoare piese de schimb (fara TVA)
                              </TableCell>
                              <TableCell align="right">
                                {valoareTotalaPieseFaraTva}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* manopera */}
                      Manopera:
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          size="small"
                          aria-label="spanning table"
                        >
                          <TableHead>
                            <TableRow size="small">
                              <TableCell>Nr.Crt</TableCell>
                              <TableCell>Cod</TableCell>
                              <TableCell>Descriere</TableCell>
                              <TableCell align="center">Cant.</TableCell>
                              <TableCell align="center">U.M.</TableCell>
                              <TableCell align="right">PU fara TVA</TableCell>
                              <TableCell align="right">Val. fara TVA</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody size="small">
                            {listaManopere?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.cod}</TableCell>
                                <TableCell>{row.descriere}</TableCell>
                                <TableCell align="center">
                                  {row.cantitate}
                                </TableCell>
                                <TableCell align="center">{row.um}</TableCell>
                                <TableCell align="right">
                                  {row.pretUnitarFaraTva}
                                </TableCell>
                                <TableCell align="right">
                                  {row.pretFaraTva}
                                </TableCell>
                              </TableRow>
                            ))}

                            <TableRow size="small">
                              <TableCell colSpan={2} />
                              <TableCell colSpan={4}>
                                Total valoare manopera (fara TVA)
                              </TableCell>
                              <TableCell align="right">
                                {valoareTotalaManoperaFaraTva}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* total comanda */}
                      <div
                        className="deviz-mic2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "4px",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: "1px",
                          }}
                        >
                          <div style={{ width: "170px" }}>
                            <div>
                              <div>
                                <strong>Total contract (fara TVA)</strong>
                              </div>
                              <div>
                                <strong>TVA</strong>
                              </div>
                              <div>
                                <strong>Total contract (cu TVA)</strong>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              width: "100px",
                              textAlign: "right",
                            }}
                          >
                            <div>{totalContractFaraTVA} RON</div>
                            <div>{tvaDeviz} RON</div>
                            <div>{totalContractCuTVA} RON</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="footer">
                      {tipDeviz === "Deviz Final" && (
                        <>
                          <div>
                            Data inchiderii: {getFormattedDate(new Date())}
                          </div>
                        </>
                      )}
                      <div className="deviz-mic2">
                        <div style={{ display: "flex" }}>
                          - Se solicita piesele si consumabilele inlocuite.{" "}
                          <div style={{ marginLeft: "15px" }}>
                            [{predarePieseVechi && "X"}] DA
                          </div>
                          <div style={{ marginLeft: "15px" }}>
                            [{!predarePieseVechi && "X"}] NU
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          - Sunt de acord cu prelucrarea datelor cu caracter
                          personal conform Legii 677/2001.{" "}
                          <div style={{ marginLeft: "15px" }}>
                            [{gdpr && "X"}] DA
                          </div>
                          <div style={{ marginLeft: "15px" }}>
                            [{!gdpr && "X"}] NU
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          - Accept / solicit utilizarea pieselor reconditionate,
                          reparate, reutilizabile.{" "}
                          <div style={{ marginLeft: "15px" }}>
                            [{acceptPieseReconditionate && "X"}] DA
                          </div>
                          <div style={{ marginLeft: "15px" }}>
                            [{!acceptPieseReconditionate && "X"}] NU
                          </div>
                        </div>
                      </div>

                      {tipDeviz === "Deviz Final" && (
                        <>
                          <div>
                            <div className="deviz-mic2">
                              <div style={{ textAlign: "center" }}>
                                CERTIFICAT DE GARANTIE
                              </div>

                              <strong>
                                Unitatea service garanteaza lucrarea executata
                                *) si conformitatea acesteia **), potrivit OUG
                                nr.140/2021.
                              </strong>
                              <div>
                                *3 luni de la data receptiei autovehiculului,
                                daca lucrarea nu a necesitat inlocuiri de piese
                                sau daca s-a executat cu piesa clientului (piese
                                care nu sunt comercializate de Prestator)
                              </div>
                              <div>
                                **Garantia pieselor furnizate de unitate, in
                                baza OUG nr.140/2021 - conf. declaratiei
                                producatorului / prin conventie intre parti,
                                conditionat de respectarea indicatiilor de
                                exploatare prevazute de producatorul
                                autovehiculului este de _______ luni.
                              </div>
                              <strong>
                                ***La cererea BENEFICIARULUI unitatea va asigura
                                vizualizarea activitatilor desfasurate si dovada
                                efectuarii inspectiilor finale.
                              </strong>
                            </div>
                          </div>
                        </>
                      )}

                      <div>
                        <div
                          className="deviz-mic"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "7px",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            RECEPTIONER <p>{frontOfficeName[0].name}</p>
                          </div>

                          <div style={{ textAlign: "center" }}>
                            <p>
                              SUNT DE ACORD CU INTRAREA IN REPARATII SI
                              EFECTUAREA
                            </p>
                            <p>EVENTUALELOR PROBE DE DRUM, IN CONDITIILE</p>
                            <p>PREZENTULUI CONTRACT, STIPULATE MAI JOS.</p>
                            <p>(nume si prenume in clar, semnatura)</p>
                            <p>{clientCar.contact}</p>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <p>CONFIRM LUCRARILE EXECUTATE, IMI ASUM CELE</p>
                            <p>STIPULATE IN CONTRACT SI SUNT DE ACORD CU</p>
                            <p>PLATA REPARATIILOR SI PREIAU AUTOVEHICULUL.</p>
                            <p>(nume si prenume in clar, semnatura)</p>
                            <p>{clientCar.contact}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="footerPage"></div>
                </div>
                {inaltime > 1 && (
                  <div className="deviz-model">
                    <div></div>
                    <div id="footerPage"></div>
                  </div>
                )}
                {inaltime > 2 && (
                  <div className="deviz-model">
                    <div></div>
                    <div id="footerPage"></div>
                  </div>
                )}
                <div className="deviz-model">
                  <div className="interior">
                    <div className="deviz-mic3">
                      <div style={{ textAlign: "center" }}>
                        <strong>CONDITII GENERALE PENTRU REPARATII</strong>
                      </div>
                      <strong>1. RECEPTIE</strong>
                      <div>
                        La receptia autovehiculului se elibereaza un Contract de
                        reparatii, in care se vor indica, dupa caz, detaliile
                        referitoare la reparatiile de efectuat, fie comanda unui
                        deviz, fie doar inregistrarea receptie autovehiculului
                        in vederea unei comenzi de lucru. Semnatura clientului
                        (sau a reprezentantului acestuia) pe Contractul de
                        reparatii implica automat consimtamantul clientului la
                        prezentele conditii generale pentru reparatii si la
                        realizarea de teste rutiere (probe de drum) necesare
                        derularii reparatiei. Reprezentantul clientului declara
                        pe propria raspundere, cu consecintele legale decurgand
                        din aceasta, ca a fost imputernicit de catre
                        proprietarul autovehiculului sa il reprezinte cu puteri
                        depline in relatia cu {user.user.companyName}, deciziile
                        acestuia reprezentand deciziile clientului insusi in
                        fata {user.user.companyName}
                      </div>
                      <strong>2. RASPUNDEREA ASUPRA BUNULUI PREDAT</strong>
                      <div>
                        {user.user.companyName} nu este responsabil decat pentru
                        accesoriile reprezentand parti integrante ale
                        autovehiculului si pentru obiectele direct incredintate
                        pentru pastrare, conform inventarului, precum si pentru
                        nivelul carburantului indicat in timpul receptiei.
                        {user.user.companyName} nu se face raspunzator pentru
                        obiectele lasate in autovehicul sau pentru eventualele
                        defecte ascunse ale autovehiculului, neconsemnate la
                        receptia acestuia sau nedeclarate de catre client.
                        Clientul sau reprezentantul acestuia declara
                        kilometrajul autovehiculului (cu titlu informativ),
                        eventualele diferente constatate ulterior in atelier
                        fiind mentionate in Contractul de reparatii. Totodata,
                        diferentele constatate ii sunt opozabile.
                      </div>
                      <strong>3. ESTIMARI - DEVIZE</strong>
                      <div>
                        La cererea clientului se va efectua fie o estimare, fie
                        un deviz al costurilor de reparatie. Oferita gratuit si
                        neimplicand demontarea, estimarea reprezinta o
                        aproximare a interventiilor de service si a costurilor
                        acestora. Devizul reprezinta lista detaliata a
                        operatiunilor de realizat si a costurilor acestora,
                        conform constatarii efectuate, cu posibila demontare sau
                        studiu in prealabil. Devizul se intocmeste conform
                        normativelor de timp ale Constructorului si a tarifelor
                        orare de manopera afisate la nivelul receptiei. Devizul
                        devine cu titlu gratuit in cazul in care acesta se
                        concretizeaza printr-un Contract de reparatii,
                        efectuandu-se astfel interventiile pentru care clientul,
                        prin semnatura, isi da acceptul in acest sens. In cazul
                        in care clientul nu doreste efectuarea reparatiei in
                        cadrul service-ului {user.user.companyName}, costul
                        devizului estimativ intocmit se va factura catre client
                        la o valoare stabilita conventional, astfel: 50 RON fara
                        TVA, daca pentru intocmirea devizului nu sunt necesare
                        interventii in atelierul de service(demontari sau alte
                        operatiuni) sau 100 RON fara TVA, daca pentru intocmirea
                        devizului sunt necesare interventii in atelierul de
                        service(demontari sau alte operatiuni).
                      </div>
                      <strong>4. OBLIGATII CONTRACTUALE</strong>
                      <div>
                        Semnarea Contractului de reparatii de catre client,
                        naste obligatia {user.user.companyName} de a efectua
                        interventiile pentru care clientul si-a dat acceptul in
                        acest sens, precum si obligatia corelativa a clientului
                        de a achita contravaloarea pieselor de schimb inlocuite
                        si a manoperei prestate de {user.user.companyName}.
                        Pentru interventiile care presupun comandarea de catre{" "}
                        {user.user.companyName} a unor piese de schimb, comanda
                        va fi efectuata numai dupa achitarea de catre client a
                        unui avans in cuantum de 30% din valoarea acestora. In
                        situatia in care ulterior clientul nu mai doreste
                        efectuarea reparatiilor, {user.user.companyName} nu va
                        returna contravaloarea avansului, iar clientul are
                        obligatia de a achita diferenta dintre avans si valoarea
                        integrala a pieselor, precum si contravaloarea
                        lucrarilor deja efectuate la autovehicul si a lucrarilor
                        de reasamblare a pieselor demontate.
                      </div>
                      <strong>5. EXECUTAREA REPARATIILOR</strong>
                      <div>
                        Lucrarile de reparatii sunt executate conform cererii
                        exprimate de catre client pe Contractul de reparatii,
                        respectandu-se normele tehnice de reparatii ale
                        Constructorului. In situatiile in care se impun, se vor
                        realiza probe de drum inainte/dupa executarea
                        lucrarilor. Daca in timpul executarii lucrarilor
                        prevazute in Contractul de reparatii apare necesitatea
                        efectuarii altor intreventii/reparatii suplimentare,
                        acestea vor face obligatoriu obiectul acceptarii lor de
                        catre client, prin semnatura sau in urma unui acord
                        prealabil, care poate fi obtinut telefonic sau prin
                        e-mail. In cazul in care clientul refuza efectuarea
                        interventiilor constatate a fi necesare in timpul
                        diagnosticarii, indiferent de natura acestora (de
                        siguranta circulatiei sau nu), prin semnatura
                        clientului,{user.user.companyName} este absolvit de
                        orice responsabilitate.
                      </div>
                      <strong>6. TERMEN ESTIMATIV DE EXECUTIE</strong>
                      <div>
                        Termenul estimativ de executie a reparatiilor solicitate
                        de catre client este stabilit de {user.user.companyName}{" "}
                        la data intocmirii Contractului de reparatii. Clientul
                        va trebui sa preia autovehiculul in cel mai scurt timp
                        dupa ce i s-a adus la cunostinta finalizarea reparatiei.
                      </div>
                      <strong>7. PIESE INLOCUITE</strong>
                      <div>
                        Clientului i se pot prezenta piesele inlocuite, daca
                        acesta si-a manifestat in mod expres cererea pe
                        Contractul de reparatii.Aceste piese ii pot fi
                        restituite, cu exceptia celor inlocuite in garantia
                        contractuala sau a celor care implica poluarea mediului
                        inconjurator. Piesele care nu au facut obiectul acestor
                        cereri in momentul livrarii autovehiculului, vor fi
                        casate.
                      </div>
                      <strong>8. PLATA</strong>
                      <div>
                        Plata se face pe loc, inainte de livrarea
                        autovehiculului catre client. Autovehiculul reparat
                        poate fi ridicat numai in urma prezentarii platii
                        efectuate, de catre client. Orice suma primita din
                        partea clientului de catre {user.user.companyName} in
                        baza Contractului de reparatii, inaintea livrarii
                        autovheiculului reparat, reprezinta un avans al sumei
                        totale de plata. Facturile sunt stabilite conform
                        tarifelor in vigoare in momentul redactarii Contractului
                        de reparatii. Manopera este calculata conform unui barem
                        de timp stabilit de normativele Constructorului. Baremul
                        de timp, precum si tarifele pieselor de schimb, se afla
                        la dispozitia clientilor, care le pot consulta fara
                        niciun fel de impediment.
                      </div>
                      <strong>9. LITIGII</strong>
                      <div>
                        Eventualele litigii decurgand prin prezentul contract
                        vor fi solutionate pe cale amiabila la sediul{" "}
                        {user.user.companyName} sau vor fi supuse spre
                        solutionare instantei judecatoresti competente de la
                        sediul {user.user.companyName}.
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <strong>
                          CERTIFICAT DE GARANTIE PIESE DE SCHIMB SI/SAU MANOPERA
                        </strong>
                      </div>
                      <div>
                        {user.user.companyName}, cu sediul in{" "}
                        {user.user.address}, in calitate de VANZATOR/PRESTATOR,
                        garanteaza calitatea si buna functionare a pieselor de
                        schimb comercializate, respectiv a lucrarilor de service
                        executate, astefel:
                      </div>
                      <div>
                        &bull; in cazul pieselor de schimb originale Peugeot &
                        Citroen, comercializate de catre {user.user.companyName}
                        si pentru lucrarile de service executate care impun
                        utilizarea acestor piese, termenul de garantie este de
                        24 luni de la data emiterii facturii aferente pentru
                        persoane fizice, respectiv de 12 luni de la data
                        emiterii facturii aferente pentru persoane juritice
                      </div>
                      <div>
                        &bull; in cazul pieselor de schimb certificate si
                        comercializate de catre {user.user.companyName} prin
                        intermediul unor furnizori de pe piata din Romania,
                        termenul de garantie este cel stipulat de catre acesti
                        furnizori in Certificatul de conformitate/Garantie al
                        pieselor respective, sau pe site-urile oficiale ale
                        acestora. In aceasta situatie, termenul de garantie
                        poate fi oricand consultat de catre client solicitand
                        aceste informatii catre {user.user.companyName} sau
                        direct pe site-urile oficiale ale furnizorilor
                        respectivi
                      </div>
                      <div>
                        &bull; in cazul lucrarilor de service care nu necesita
                        utilizarea unor piese de schimb, ci doar operatiuni de
                        manopera, termenul de garantie acordat este de 3 luni de
                        la data emiterii facturii aferente
                      </div>
                      <div>
                        &bull; in cazul in care clientul solicita montarea unor
                        piese de schimb furnizate de catre el insusi,{" "}
                        {user.user.companyName} poate fi de acord cu efectuarea
                        acestor lucrari, doar daca clientul prezinta in original
                        documentele care sa ateste achizitia, conformitatea si
                        garantia respectivelor piese de schimb (factura
                        achizitie, Certificat de conformitate, Certificat de
                        garantie). In aceasta situatie, {user.user.companyName}{" "}
                        garanteaza calitatea lucrarilor efectuate, iar termenul
                        de garantie este de 3 luni de la data emiterii facturii
                        aferente si este acordat doar in cazul in care
                        eventualele defectiuni apar in urma unui montaj
                        necorespunzator.
                      </div>
                      <div>
                        Pana la proba contrara, orice neconformitate constatata
                        in termen de 1 an de la livrarea produsului catre un
                        consumator se prezuma ca a existat la momentul livrarii
                        acestuia, cu exceptia cazurilor in care prezumtia este
                        incompatibila cu natura produsului sau cu natura
                        neconformitatii. Peste acest termen,
                        VANZATORUL/PRESTATORUL este responsabil numai daca se
                        dovedeste ca bunul nu era in conformitate la momentul
                        livrarii sau daca problema nu a aparut ca urmare a
                        uzurii normale, a unui eveniment neprevazut sau a
                        exploatarii anormale, etc.
                      </div>
                      <div>
                        In cazul reparatiilor efectuate sub incidenta garantiei,
                        termenul de finalizare a reparatiei
                        neconformitatii/defectului semnalat de client si
                        constatat de {user.user.companyName} nu poate depasi 15
                        zile calendaristice si incepe sa curga de la data
                        predarii in service a autovehicului. Termenul de
                        finalizare a reparatiei se suspenda pe perioada in care{" "}
                        {user.user.companyName}, independent de vointa sa, este
                        nevoit sa astepte furnizarea unei piese de schimb sau a
                        unei solutii tehnice de la Producator, urmand sa
                        instiinteze in scris sau telefonic clientul despre
                        aceasta situatie. Astfel clientul a fost informat,
                        intelege si este de acord cu aceasta clauza.
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <strong>PROTECTIA DATELOR CU CARACTER PERSONAL</strong>
                      </div>
                      <div>
                        Tinand cont de faptul ca, in executarea Contractului de
                        reparatii, {user.user.companyName}, in calitate de
                        Operator de date cu caracter personal, va prelucra
                        datele puse la dispozitie de catre client(sau de catre
                        reprezentantul acestuia), se obliga sa respecte
                        urmatoarele regului:
                      </div>
                      <div>
                        &bull; va limita prelucrarea datelor cu caracter
                        personal doar la strictul necesar pentru executarea
                        Contractului de reparatii, intocmai cum sunt precizate
                        in Conditiile generale pentru reparatii dar si pentru
                        executarea facturii fiscale
                      </div>
                      <div>
                        &bull; operatiunile de prelucrare a datelor personale se
                        vor limita la: acces, inregistrare, structurare,
                        stocare, utilizare pentru executarea Contractului de
                        reparatii si a facturii fiscale iar la final, datele sa
                        fie pastrate pentru dovedirea prestatiei fiscale pentru
                        o periaoda de 10 ani, urmand ca apoi sa fie sterse.
                      </div>
                      <div>
                        Va rugam sa accesati http://www.autoviva.ro/gdpr sau sa
                        solicitati catre adresa contact@m-service.ro ori la
                        receptie detalii complete cu privire la prelucrarea
                        datelor cu caracter personal, in cazul in care nu ati
                        fost deja informati cu privire la prelucrarea datelor in
                        conformitate cu Regulamentul 679/2016.
                      </div>
                    </div>
                  </div>
                  <div id="footerPage"></div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Print;
