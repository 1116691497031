import useAuth from "../../hooks/useAuth";
import DevizNouForm from "./devizNou/DevizNouForm";
import { useGetMechanicsQuery } from "../mechanics/mechanicsApiSlice";
import { useGetFrontOfficesQuery } from "../frontOffice/frontOfficesApiSlice";
import { useGetFurnizoriQuery } from "../furnizori/furnizoriApiSlice";
import { useGetDevizeQuery } from "./devizeApiSlice";
import { useParams } from "react-router-dom";

function EditDeviz() {
  const { id } = useAuth();

  const { idDeviz } = useParams();

  const { devize } = useGetDevizeQuery(id, {
    selectFromResult: ({ data }) => ({
      devize: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });

  function getDeviz(idDeviz) {
    let foundDeviz = devize.find((e) => e._id === idDeviz);
    return foundDeviz;
  }

  const { mechanics } = useGetMechanicsQuery(id, {
    selectFromResult: ({ data }) => ({
      mechanics: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });
  const resultsMechanics = mechanics.filter((element) => {
    return element.employee === true;
  });

  const { frontOffices } = useGetFrontOfficesQuery(id, {
    selectFromResult: ({ data }) => ({
      frontOffices: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });
  const resultsFrontOffices = frontOffices.filter((element) => {
    return element.employee === true;
  });

  const { furnizori } = useGetFurnizoriQuery(id, {
    selectFromResult: ({ data }) => ({
      furnizori: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });
  const resultsFurnizori = furnizori.filter((element) => {
    return element.colaborare === true;
  });

  const content = (
    <DevizNouForm
      userId={id}
      mechanics={resultsMechanics}
      frontOffices={resultsFrontOffices}
      furnizori={resultsFurnizori}
      idDeviz={getDeviz(idDeviz)}
    />
  );

  return content;
}

export default EditDeviz;
