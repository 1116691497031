import React from "react";
import ClientsList from "../features/clients/ClientsList";

function Clienti() {
  return (
    <>
      <ClientsList />
    </>
  );
}

export default Clienti;
