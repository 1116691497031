export function luni(month) {
  switch (month) {
    case "January":
      return "Ianuarie";
    case "February":
      return "Februarie";
    case "March":
      return "Martie";
    case "April":
      return "Aprilie";
    case "May":
      return "Mai";
    case "June":
      return "Iunie";
    case "July":
      return "Iulie";
    case "August":
      return "August";
    case "September":
      return "Septembrie";
    case "October":
      return "Octombrie";
    case "November":
      return "Noiembrie";
    case "December":
      return "Decembrie";
    default:
  }
}

export function saptamani(sapt) {
  switch (sapt) {
    case "MONDAY":
      return "LUNI";
    case "TUESDAY":
      return "MARTI";
    case "WEDNESDAY":
      return "MIERCURI";
    case "THURSDAY":
      return "JOI";
    case "FRIDAY":
      return "VINERI";
    case "SATURDAY":
      return "SAMBATA";
    case "SUNDAY":
      return "DUMINICA";

    default:
  }
}

export function saptamaniMic(sapt) {
  switch (sapt) {
    case "Monday":
      return "Luni";
    case "Tuesday":
      return "Marti";
    case "Wednesday":
      return "Miercuri";
    case "Thursday":
      return "Joi";
    case "Friday":
      return "Vineri";
    case "Saturday":
      return "Sambata";
    case "Sunday":
      return "Duminica";

    default:
  }
}
