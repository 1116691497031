import React from "react";
import UsersList from "../features/user/UsersList";
import MechanicsList from "../features/mechanics/MechanicsList";
import FrontOfficesList from "../features/frontOffice/FrontOfficeList";
import FurnizoriList from "../features/furnizori/FurnizoriList";
import { Divider } from "antd";

function Setari() {
  return (
    <>
      <UsersList />
      <div>
        <Divider />
      </div>
      <MechanicsList />
      <div>
        <Divider />
      </div>
      <FrontOfficesList />
      <div>
        <Divider />
      </div>
      <FurnizoriList />
    </>
  );
}

export default Setari;
