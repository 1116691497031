import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import styles from "./Deviz.module.css";
import { Modal, Button as Button2, Input, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { piesaInitial } from "../initialState";

const PieseDeviz = ({
  piesa,
  setPiesa,
  listaPiese,
  setListaPiese,
  setValoareTotalaPieseFaraTva,
  TAX_RATE,
  furnizoriId,
  setFurnizoriId,
  furnizori,
}) => {
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const onPiesaChanged = (e) => {
    const { name, value } = e.target;
    setPiesa({
      ...piesa,
      [name]: value,
    });
  };

  const onPiesaCheckChanged = (e) => {
    const { name, checked } = e.target;
    setPiesa({
      ...piesa,
      [name]: checked,
    });
  };

  const handleClickListaPreturi = (e) => {
    e.preventDefault();
    const furnizor =
      piesa.pieseDezmembrari || piesa.pieseDezmembrariClient
        ? "dezmembrari"
        : furnizoriId;

    const canSave = [piesa.descriere, piesa.cantitate, piesa.um].every(Boolean);

    if (editingItem === null) {
      if (canSave) {
        setListaPiese([
          ...listaPiese,
          {
            id: Math.floor(Math.random() * 10000000000001),
            codOE: piesa.codOE,
            cod: piesa.cod,
            descriere: piesa.descriere.toUpperCase(),
            cantitate: piesa.cantitate,
            um: piesa.um.toUpperCase() || "BUC",
            pretAchizitie: piesa.pretAchizitie,
            pretAchizitieFaraTva: piesa.pretAchizitieFaraTva,
            adaos: piesa.adaos,
            pretUnitarFaraTva: piesa.pretUnitarFaraTva,
            pretFaraTva: piesa.pretFaraTva,
            furnizor: furnizor,
            pieseDezmembrari: piesa.pieseDezmembrari,
            pieseDezmembrariClient: piesa.pieseDezmembrariClient,
          },
        ]);
        message.success("Piesa a fost adaugata!");
        setPiesa(piesaInitial);
      } else {
        !piesa.descriere &&
          message.error(`Va rugam sa introduceti o descriere!`);
        !piesa.cantitate &&
          message.error(`Va rugam sa introduceti o cantitate!`);
        !piesa.um &&
          message.error(`Va rugam sa introduceti o unitate de masura!`);
      }
    } else {
      if (canSave) {
        const editPiesa = listaPiese.map((obj) => {
          if (obj.id === editingItem.id) {
            return {
              ...obj,
              codOE: piesa.codOE,
              cod: piesa.cod,
              descriere: piesa.descriere.toUpperCase(),
              cantitate: piesa.cantitate,
              um: piesa.um.toUpperCase() || "BUC",
              pretAchizitie: piesa.pretAchizitie,
              pretAchizitieFaraTva: piesa.pretAchizitieFaraTva,
              adaos: piesa.adaos,
              pretUnitarFaraTva: piesa.pretUnitarFaraTva,
              pretFaraTva: piesa.pretFaraTva,
              furnizor: furnizor,
            };
          }
          return obj;
        });
        message.success("Piesa a fost modificata!");
        setListaPiese(editPiesa);

        setEditingItem(null);
        setAddEditModalVisibilty(false);
      } else {
        !piesa.descriere &&
          message.error(`Va rugam sa introduceti o descriere!`);
        !piesa.cantitate &&
          message.error(`Va rugam sa introduceti o cantitate!`);
        !piesa.um &&
          message.error(`Va rugam sa introduceti o unitate de masura!`);
      }
    }
  };

  const handleRemoveField = (id) => {
    const newList = listaPiese.filter((piesa) => piesa.id !== id);
    setListaPiese(newList);
  };

  const pieseSubtotal = subtotalPiese(listaPiese);

  const handleAchizitieFaraTvaInput = (event) => {
    const resPret = (event.target.value * (TAX_RATE + 1)).toFixed(2);
    setPiesa({
      ...piesa,
      pretAchizitieFaraTva: event.target.value,
      pretAchizitie: resPret,
    });
  };

  const handleAchizitieCuTvaInput = (event) => {
    const resPret = (event.target.value / (TAX_RATE + 1)).toFixed(2);
    setPiesa({
      ...piesa,
      pretAchizitieFaraTva: resPret,
      pretAchizitie: event.target.value,
    });
  };

  useEffect(() => {
    const calcFaraTva = () => {
      const pretUnitarFaraTva = (
        (piesa.pretAchizitie / (TAX_RATE + 1)) *
        (piesa.adaos / 100 + 1)
      ).toFixed(2);
      const pretFaraTva = Number(
        (pretUnitarFaraTva * piesa.cantitate).toFixed(2)
      );
      setPiesa({
        ...piesa,
        pretUnitarFaraTva: pretUnitarFaraTva,
        pretFaraTva: pretFaraTva,
      });
    };
    calcFaraTva();
    setValoareTotalaPieseFaraTva(pieseSubtotal.toFixed(2));
    // eslint-disable-next-line
  }, [
    piesa.pretAchizitie,
    piesa.adaos,
    piesa.cantitate,
    pieseSubtotal,
    TAX_RATE,
    // piesa, daca o pun randeaza in continuu
    setPiesa,
    setValoareTotalaPieseFaraTva,
  ]);

  function subtotalPiese(items) {
    return items
      .map(({ pretFaraTva }) => pretFaraTva)
      .reduce((sum, i) => sum + i, 0);
  }

  const onFurnizoriIdChanged = (e) => setFurnizoriId(e.target.value);

  const optionsFurnizori = furnizori.map((furnizor) => {
    return (
      <option key={furnizor.id} value={furnizor.id}>
        {furnizor.name}
      </option>
    );
  });

  function getFurnizorName(id) {
    let foundName = furnizori.find((e) => e.id === id);
    return foundName.name;
  }

  return (
    <>
      <Grid
        item
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Container>
          <div className="d-flex justify-content-between">
            <Typography
              variant="overline"
              style={{ color: "gray", paddingRight: "3px" }}
              gutterBottom
            >
              Piese de schimb utilizate:
            </Typography>

            <Button2
              style={{ width: 145 }}
              type="primary"
              onClick={() => {
                setAddEditModalVisibilty(true);
                setPiesa(piesaInitial);
              }}
            >
              Adauga piesa
            </Button2>
          </div>
        </Container>
        <Container>
          <TableContainer component={Paper} className="tb-container">
            <Table
              style={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nr.Crt</TableCell>
                  <TableCell align="left">Cod OE</TableCell>
                  <TableCell align="left">Cod</TableCell>
                  <TableCell align="left">Descriere</TableCell>
                  <TableCell align="center">Cant.</TableCell>
                  <TableCell align="center">U.M.</TableCell>
                  <TableCell align="center">PU fara TVA</TableCell>
                  <TableCell align="center">Val. fara TVA</TableCell>
                  <TableCell align="center">Furnizor</TableCell>
                  <TableCell align="center">Actiuni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaPiese?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.codOE}</TableCell>
                    <TableCell align="left">{row.cod}</TableCell>
                    <TableCell align="left">{row.descriere}</TableCell>
                    <TableCell align="center">{row.cantitate}</TableCell>
                    <TableCell align="center">{row.um}</TableCell>
                    <TableCell align="center">
                      {row.pretUnitarFaraTva}
                    </TableCell>
                    <TableCell align="center">{row.pretFaraTva}</TableCell>
                    <TableCell align="center">
                      {row.pieseDezmembrari ? (
                        <span>Dez.</span>
                      ) : row.pieseDezmembrariClient ? (
                        <span>Client</span>
                      ) : (
                        <span>{getFurnizorName(row.furnizor)}</span>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <EditOutlined
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => {
                          setEditingItem(row);
                          setAddEditModalVisibilty(true);
                          setPiesa(row);
                        }}
                      />
                      {"  "}
                      <DeleteOutlined
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => handleRemoveField(row.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell colSpan={3}>
                    Total valoare piese de schimb (fara TVA)
                  </TableCell>
                  <TableCell align="center">
                    {pieseSubtotal.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>

      {/* adaugare si editare piese */}
      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null);
            setAddEditModalVisibilty(false);
          }}
          open={addEditModalVisibilty}
          centered
          title={`${editingItem !== null ? "Modifica piesa" : "Adauga piesa"}`}
          footer={false}
        >
          <Grid>
            <Typography variant="body3">
              {!editingItem && (
                <div>
                  {!piesa.pieseDezmembrariClient && (
                    <div>
                      <input
                        type="checkbox"
                        name="pieseDezmembrari" //IMPORTANT
                        label="PieseDezmembrari"
                        checked={piesa.pieseDezmembrari}
                        onChange={onPiesaCheckChanged}
                      />{" "}
                      Piesa dezmembrari
                    </div>
                  )}
                  {!piesa.pieseDezmembrari && (
                    <div>
                      <input
                        type="checkbox"
                        name="pieseDezmembrariClient" //IMPORTANT
                        label="Descriere"
                        checked={piesa.pieseDezmembrariClient}
                        onChange={onPiesaCheckChanged}
                      />{" "}
                      Piesa furnizata de client
                    </div>
                  )}
                </div>
              )}

              {piesa.pieseDezmembrari || piesa.pieseDezmembrariClient ? (
                ""
              ) : (
                <div className={styles.inputDivForm}>
                  <div style={{ width: 65 }}>Cod OE:</div>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="codOE" //IMPORTANT
                    value={piesa.codOE}
                    label="CodOE"
                    onChange={onPiesaChanged}
                  />
                </div>
              )}
              <div className={styles.inputDivForm}>
                <div style={{ width: 38 }}>Cod:</div>
                <Input
                  type="text"
                  autoComplete="off"
                  name="cod" //IMPORTANT
                  label="Cod"
                  value={piesa.cod}
                  onChange={onPiesaChanged}
                />
              </div>
              <div className={styles.inputDivForm}>
                <div style={{ width: 78 }}>Descriere:</div>
                <Input
                  //className={styles.noOutlineForm}
                  type="text"
                  autoComplete="off"
                  name="descriere" //IMPORTANT
                  label="Descriere"
                  value={piesa.descriere}
                  onChange={onPiesaChanged}
                />{" "}
              </div>
              <div className={styles.inputDivJos}>
                <div className={styles.inputDivForm2}>
                  Cantitate:{" "}
                  <Input
                    style={{ width: 40 }}
                    type="text"
                    autoComplete="off"
                    name="cantitate" //IMPORTANT
                    label="Cantitate"
                    value={piesa.cantitate}
                    onChange={onPiesaChanged}
                  />{" "}
                </div>
                <div className={styles.inputDivForm2}>
                  U.M.:{" "}
                  <Input
                    style={{ width: 60 }}
                    type="text"
                    name="um" //IMPORTANT
                    label="UM"
                    value={piesa.um}
                    onChange={onPiesaChanged}
                  />{" "}
                </div>
                {!piesa.pieseDezmembrariClient && (
                  <>
                    <div className={styles.inputDivForm2}>
                      Pret Achizitie cu tva:{" "}
                      <Input
                        style={{ width: 80 }}
                        type="number"
                        autoComplete="off"
                        name="pretAchizitie" //IMPORTANT
                        label="Pret achizitie"
                        value={piesa.pretAchizitie}
                        onChange={handleAchizitieCuTvaInput}
                      />{" "}
                    </div>
                    <div className={styles.inputDivForm2}>
                      Pret Achizitie fara tva:{" "}
                      <Input
                        style={{ width: 80 }}
                        type="number"
                        autoComplete="off"
                        name="pretAchizitieFaraTva" //IMPORTANT
                        label="Pret achizitie fara tva"
                        value={piesa.pretAchizitieFaraTva}
                        onChange={handleAchizitieFaraTvaInput}
                      />{" "}
                    </div>
                    <div className={styles.inputDivForm2}>
                      Adaos:{" "}
                      <Input
                        style={{ width: 50 }}
                        type="number"
                        autoComplete="off"
                        name="adaos" //IMPORTANT
                        label="Adaos"
                        value={piesa.adaos}
                        onChange={onPiesaChanged}
                      />{" "}
                    </div>
                    {/* <div>Pret unitar fara tva: {piesa.pretUnitarFaraTva} </div>
                    <div>Pret fara tva: {piesa.pretFaraTva} </div> */}
                    <div className={styles.inputDivForm2}>
                      P.U. fara tva:{" "}
                      <Input
                        style={{ width: 80 }}
                        type="number"
                        name="pretUnitarFaraTva" //IMPORTANT
                        label="pretUnitarFaraTva"
                        value={piesa.pretUnitarFaraTva}
                        onChange={onPiesaChanged}
                        disabled
                      />{" "}
                    </div>
                    <div className={styles.inputDivForm2}>
                      Pret fara tva:{" "}
                      <Input
                        style={{ width: 80 }}
                        type="number"
                        name="pretFaraTva" //IMPORTANT
                        label="PretFaraTva"
                        value={piesa.pretFaraTva}
                        onChange={onPiesaChanged}
                        disabled
                      />{" "}
                    </div>
                  </>
                )}

                <div
                  hidden={
                    piesa.pieseDezmembrari || piesa.pieseDezmembrariClient
                  }
                  className={styles.inputDivForm2}
                >
                  Furnizor:{" "}
                  <select value={furnizoriId} onChange={onFurnizoriIdChanged}>
                    {optionsFurnizori}
                  </select>{" "}
                </div>
              </div>
            </Typography>
          </Grid>

          <div className="d-flex justify-content-end">
            <Button2 type="primary" onClick={handleClickListaPreturi}>{`${
              editingItem !== null ? "Salveaza" : "Adauga"
            }`}</Button2>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PieseDeviz;
