import React, { useEffect, useState } from "react";
import { useGetDevizeQuery } from "../devize/devizeApiSlice";
import { useGetCarsQuery } from "../cars/carsApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { Card, Button, Divider } from "antd";

import { useGetMechanicsQuery } from "../mechanics/mechanicsApiSlice";

function ManoperaMecanici() {
  const [devizeData, setDevizeData] = useState([]);

  const [manopereInAsteptare, setManopereInAsteptare] = useState([]);
  const [manopereInLucru, setManopereInLucru] = useState([]);

  const { id } = useAuth();

  const { mechanics } = useGetMechanicsQuery(id, {
    selectFromResult: ({ data }) => ({
      mechanics: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });

  const resultsMechanics = mechanics.filter((element) => {
    return element.employee === true;
  });

  const { cars } = useGetCarsQuery(id, {
    selectFromResult: ({ data }) => ({
      cars: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  function getCar(id) {
    let foundCar = cars.find((e) => e._id === id);
    return foundCar;
  }

  const {
    data: devize,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetDevizeQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const getDevize = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(devize?.entities).map(
        (key) => devize.entities[key]
      );
      setDevizeData(result);
    }
  };

  useEffect(() => {
    getDevize();
    // eslint-disable-next-line
  }, [devize]);

  useEffect(() => {
    let inAsteptare = [];
    let inLucru = [];
    devizeData?.forEach((o) => {
      if (o.status === "inAsteptare") {
        inAsteptare.push(o);
      }
      if (o.status === "inLucru") {
        inLucru.push(o);
      }
    });

    const listaManopereFiltrata = inAsteptare.map((obj) => {
      const manopere = obj.manopera;
      const manopera = manopere.map((o) => {
        return {
          id: o.id,
          descriere: o.descriere,
          mecanic: o.mecanic,
          car: obj.car,
        };
      });
      return manopera;
    });
    const listaManopere = Array.prototype.concat(...listaManopereFiltrata);

    const manopereMecanici = resultsMechanics?.map((mecanic, index) => {
      const listaManMecanic = listaManopere.filter((manopera) => {
        return manopera.mecanic === mecanic.id;
      });

      return {
        id: index,
        name: mecanic.name,
        color: mecanic.color,
        manopere: listaManMecanic,
      };
    });

    //________________________________________________________

    const listaManopereFiltrataInLucru = inLucru.map((obj) => {
      const manopere = obj.manopera;
      const manopera = manopere.map((o) => {
        return {
          id: o.id,
          descriere: o.descriere,
          mecanic: o.mecanic,
          car: obj.car,
        };
      });
      return manopera;
    });
    const listaManopereInLucru = Array.prototype.concat(
      ...listaManopereFiltrataInLucru
    );

    const manopereMecaniciInLucru = resultsMechanics?.map((mecanic, index) => {
      const listaManMecanic = listaManopereInLucru.filter((manopera) => {
        return manopera.mecanic === mecanic.id;
      });

      return {
        id: index,
        name: mecanic.name,
        color: mecanic.color,
        manopere: listaManMecanic,
      };
    });

    setManopereInAsteptare(manopereMecanici);
    setManopereInLucru(manopereMecaniciInLucru);

    // eslint-disable-next-line
  }, [devizeData]);

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      <div className="d-flex justify-content-between">
        <h3>Activitate Mecanici</h3>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      <Divider />
      <h5>Autovehicule care trebuie sa intre in reparatie</h5>
      <div className="d-flex justify-content-center align-items-center flex-wrap text-dark">
        {isSuccess &&
          manopereInAsteptare?.map((item) => (
            <Card
              key={item.id}
              style={{
                width: "600px",
                display: "flex",
                flexDirection: "row",
                borderColor: `${item.color}`,
                borderWidth: "3px",
              }}
              className="m-3 "
            >
              <strong>Nume: {item.name}</strong>
              {item.manopere?.map((item2) => (
                <div key={item2.id}>
                  <div>
                    &bull; {getCar(item2.car).marca} {getCar(item2.car).model} -{" "}
                    {getCar(item2.car).nrinmatriculare} - {item2.descriere}
                  </div>
                </div>
              ))}
            </Card>
          ))}
      </div>

      <Divider />
      <h5>Autovehicule care sunt in lucru</h5>
      <div className="d-flex justify-content-center align-items-center flex-wrap text-dark">
        {isSuccess &&
          manopereInLucru?.map((item) => (
            <Card
              key={item.id}
              style={{
                width: "600px",
                display: "flex",
                flexDirection: "row",
                borderColor: `${item.color}`,
                borderWidth: "3px",
              }}
              className="m-3 "
            >
              <strong>Nume: {item.name}</strong>
              {item.manopere?.map((item2) => (
                <div key={item2.id}>
                  <div>
                    &bull; {getCar(item2.car).marca} {getCar(item2.car).model} -{" "}
                    {getCar(item2.car).nrinmatriculare} - {item2.descriere}
                  </div>
                </div>
              ))}
            </Card>
          ))}
      </div>
    </>
  );

  return content;
}

export default ManoperaMecanici;
