import React, { useEffect, useState } from "react";
import {
  useGetFurnizoriQuery,
  useUpdateFurnizorMutation,
  useDeleteFurnizorMutation,
  useAddNewFurnizorMutation,
} from "./furnizoriApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Checkbox, Input, message, Modal, Table } from "antd";

function FurnizoriList() {
  const [furnizoriData, setFurnizoriData] = useState([]);
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const { id } = useAuth();

  const {
    data: furnizori,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetFurnizoriQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [deleteFurnizor] = useDeleteFurnizorMutation();

  const [updateFurnizor] = useUpdateFurnizorMutation();

  const onDeleteFurnizorClicked = async (record) => {
    try {
      message.success("Furnizorul a fost sters cu succes");
      await deleteFurnizor({ id: record }).unwrap();
    } catch (err) {
      message.error(
        "Nu se poate sterge acest furnizor, exista informatii in baza de date legate de el"
      );
    }
  };

  const [addNewFurnizor] = useAddNewFurnizorMutation();

  const getFurnizori = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(furnizori?.entities).map(
        (key) => furnizori.entities[key]
      );
      setFurnizoriData(result);
    }
  };

  useEffect(() => {
    getFurnizori();
    // eslint-disable-next-line
  }, [furnizori]);

  //_________________________________________________________________

  const date = (timestamp) => new Date(timestamp).toLocaleString();

  //__________________________________________________________________

  const columns = [
    {
      title: "Nume furnizor",
      dataIndex: "name",
    },
    {
      title: "Stare colaborare",
      dataIndex: "colaborare",
      render: (colaborare) => (
        <div className="d-flex">
          {colaborare ? (
            <span>Contract deschis</span>
          ) : (
            <span style={{ color: "red" }}>Contract inchis</span>
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.colaborare - b.colaborare,
      },
      sortOrder: "descend",
      // defaultSortOrder: "descend",
    },
    {
      title: "Data adaugarii",
      dataIndex: "createdAt",
      render: (createdAt) => <div>{date(createdAt)}</div>,
    },
    {
      title: "Data modificarii",
      dataIndex: "updatedAt",
      render: (updatedAt) => <div>{date(updatedAt)}</div>,
    },
    {
      title: "Modificari",
      dataIndex: "id",
      render: (id, record) => (
        <div className="d-flex">
          <EditOutlined
            className="mx-2"
            onClick={() => {
              setEditingItem(record);
              setAddEditModalVisibilty(true);
            }}
          />
          <DeleteOutlined
            className="mx-2"
            onClick={() => onDeleteFurnizorClicked(id)}
          />
        </div>
      ),
    },
  ];

  const onFinish = async (values) => {
    if (editingItem === null) {
      const canSave = [values.name, id].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await addNewFurnizor({ user: id, name: values.name }).unwrap();
          /// se pune unrap ca este in interiorul try catch block
          message.success("Furnizorul a fost adaugat cu succes");
          setAddEditModalVisibilty(false);
        } catch (err) {
          message.error("Nu se poate salva acest furnizor", err);
        }
      } else {
        message.error("Introdu un nume de furnizor");
      }
    } else {
      const canSave = [values.name, values.user].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await updateFurnizor({
            id: values.id,
            user: values.user,
            name: values.name,
            colaborare: values.colaborare,
          }).unwrap();

          message.success("Datele furnizorului modificate cu succes");
          setAddEditModalVisibilty(false);
          setEditingItem(null);
        } catch (err) {
          message.error("Nu se poate modifica acest furnizor");
        }
      } else {
        message.error("Introdu un nume de furnizor");
      }
    }
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      <div className="d-flex justify-content-between">
        <h3>Furnizori</h3>
        <Button
          style={{ width: 155 }}
          type="primary"
          onClick={() => setAddEditModalVisibilty(true)}
        >
          Adauga Furnizor
        </Button>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      {isSuccess && (
        <Table
          columns={columns}
          dataSource={furnizoriData}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ["5", "10"],
            showSizeChanger: true,
          }}
        />
      )}

      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null);
            setAddEditModalVisibilty(false);
          }}
          open={addEditModalVisibilty}
          centered
          title={`${
            editingItem !== null ? "Modifica furnizor" : "Adauga furnizor"
          }`}
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item name="id" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="user" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>

            {editingItem && (
              <Form.Item
                name="colaborare"
                label="Stare colaborare"
                valuePropName="checked"
              >
                <Checkbox>Colaborare?</Checkbox>
              </Form.Item>
            )}

            <div className="d-flex justify-content-end">
              <Button htmlType="submit" type="primary">
                {`${editingItem !== null ? "Salveaza" : "Adauga"}`}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );

  return content;
}

export default FurnizoriList;
