import React from "react";
import ServiceList from "../features/service/ServiceList";

function Service() {
  return (
    <>
      <ServiceList />
    </>
  );
}

export default Service;
