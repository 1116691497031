import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isManager = false;
  let isAdmin = false;
  let status = "Employee";

  if (token) {
    const decoded = jwtDecode(token);
    const { username, companyName, roles, _id } = decoded.UserInfo;

    isManager = roles.includes("Manager");
    isAdmin = roles.includes("Admin");

    if (isManager) status = "Manager";
    if (isAdmin) status = "Admin";

    return {
      username,
      companyName,
      roles,
      status,
      isManager,
      isAdmin,
      id: _id,
    };
  }

  return {
    id: null,
    username: "",
    companyName: "",
    roles: [],
    isManager,
    isAdmin,
    status,
  };
};
export default useAuth;
