import { useNavigate } from "react-router-dom";

import logo from "../resourses/logo.png";
import { Outlet } from "react-router-dom";

import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  UserOutlined,
  DashboardOutlined,
  ScheduleOutlined,
  PoweroffOutlined,
  CarOutlined,
  SettingOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";

const { Header, Sider, Content } = Layout;

function DefaultLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const [sendLogout] = useSendLogoutMutation();

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        height: "100vh",
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          paddingTop: 10,
        }}
      >
        <div className="logo">
          {collapsed ? (
            <img
              style={{
                width: 60,
                height: 30,
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 15,
                transitionDuration: "400ms",
              }}
              src={logo}
              alt="Logo"
            />
          ) : (
            <img
              style={{
                width: 120,
                height: 60,
                marginLeft: 15,
                transitionDuration: "450ms",
              }}
              src={logo}
              alt="Logo"
            />
          )}
        </div>

        <Menu
          onClick={({ key }) => {
            navigate(key);
          }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "/dash",
              icon: <HomeOutlined />,
              label: "Acasa",
            },
            {
              key: "/dash/devize",
              icon: <DashboardOutlined />,
              label: "Devize",
            },
            {
              key: "/dash/clienti",
              icon: <UserOutlined />,
              label: "Clienti",
            },
            {
              key: "/dash/service",
              icon: <CarOutlined />,
              label: "Service",
            },
            {
              key: "/dash/programari",
              icon: <ScheduleOutlined />,
              label: "Programari",
            },
            {
              key: "/dash/rapoarte",
              icon: <LineChartOutlined />,
              label: "Rapoarte",
            },
            {
              key: "/dash/users",
              icon: <SettingOutlined />,
              label: "Setari",
            },
            {
              icon: <PoweroffOutlined />,
              label: "Deconectare",
              danger: true,
              onClick: sendLogout,
              key: "/",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            paddingLeft: 20,
            color: "white",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            marginRight: "24px",
            marginLeft: "24px",
            marginTop: "12px",
            marginBottom: "12px",
            paddingRight: 24,
            paddingLeft: 24,
            minHeight: 280,
          }}
        >
          <div
            style={{
              overflowY: "scroll",
              height: "100%",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
