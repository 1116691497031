import React, { useEffect } from "react";
import { useGetCarsQuery } from "../../cars/carsApiSlice";
import { useGetClientsQuery } from "../../clients/clientsApiSlice";
import styles from "./Deviz.module.css";
import {
  Button,
  Grid,
  Container,
  Typography,
  Autocomplete,
  Avatar,
  Chip,
  TextField,
  Paper,
} from "@mui/material";

const VehClient = ({
  userId,
  car,
  setCar,
  client,
  setClient,
  km,
  setKm,
  nivelDevizCarburant,
  setNivelDevizCarburant,
  carId,
}) => {
  const { cars } = useGetCarsQuery(userId, {
    selectFromResult: ({ data }) => ({
      cars: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const idCar = localStorage.getItem("idCar");
  if (idCar) {
    carId = idCar;
  }

  function getCar(carId) {
    let foundCar = cars.find((e) => e._id === carId);
    return foundCar;
  }
  useEffect(() => {
    if (carId) {
      setCar(getCar(carId));
    }
    // eslint-disable-next-line
  }, [carId, setCar]);

  const { clients } = useGetClientsQuery(userId, {
    selectFromResult: ({ data }) => ({
      clients: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  useEffect(() => {
    if (car) {
      const clientCar = clients?.find((e) => e.id === car.client);
      setClient(clientCar);
    } else {
      const clientCar = {
        name: "",
        adresa: "",
        localitate: "",
        judet: "",
        cuicnp: "",
        contact: "",
        telefon: "",
      };
      setClient(clientCar);
    }
    // eslint-disable-next-line
  }, [car, setClient]);

  const carsProps = {
    options: cars,
    getOptionLabel: (option) => `${option.nrinmatriculare} - ${option.vin}`,
  };

  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  // const handleChange = (event) => {
  //   setType(event.target.value);
  // };

  const onKmChanged = (e) => setKm(e.target.value);
  const onNivelDevizCarburantChanged = (e) =>
    setNivelDevizCarburant(e.target.value);

  return (
    <>
      <Grid item style={{ width: "50%" }}>
        <Container>
          <Typography
            variant="overline"
            style={{ color: "gray", paddingRight: "3px" }}
            gutterBottom
          >
            Vehicul client:
          </Typography>

          {car && (
            <>
              <Typography variant="subtitle2" gutterBottom>
                Marca: {car.marca} - Model: {car.model}
              </Typography>
              <Typography variant="body2">
                Nr. inmatriculare: {car.nrinmatriculare}
              </Typography>
              <Typography variant="body2">Serie sasiu: {car.vin}</Typography>
              <Typography variant="body2">
                Data fabricatiei: {car.fabricatie}
              </Typography>
              <Typography variant="body2">
                KM:{" "}
                <input
                  className={styles.noOutline}
                  type="number"
                  autoComplete="off"
                  value={km}
                  onChange={onKmChanged}
                />
              </Typography>
              <Typography variant="body2">
                Nivel carburant:{" "}
                <select
                  value={nivelDevizCarburant}
                  label="Nivel carburant"
                  onChange={onNivelDevizCarburantChanged}
                >
                  <option value="1/8">1/8</option>
                  <option value="2/8">2/8</option>
                  <option value="3/8">3/8</option>
                  <option value="4/8">4/8</option>
                  <option value="5/8">5/8</option>
                  <option value="6/8">6/8</option>
                  <option value="7/8">7/8</option>
                  <option value="8/8">8/8</option>
                </select>
              </Typography>

              <Button
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
                onClick={() => setCar(null)}
              >
                Schimba
              </Button>
            </>
          )}
          <div style={car ? { display: "none" } : { display: "block" }}>
            <Autocomplete
              {...carsProps}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // required={!invoice && true}
                  label="Selecteaza un vehicul"
                  margin="normal"
                  variant="outlined"
                />
              )}
              value={cars?.vin}
              onChange={(event, value) => setCar(value)}
            />
          </div>
          {!car && (
            <>
              <Grid item style={{ paddingBottom: "10px" }}>
                <Chip
                  avatar={<Avatar>+</Avatar>}
                  label="Adauga vehicul nou"
                  //onClick={() => setOpen(true)}
                  variant="outlined"
                />
              </Grid>
            </>
          )}
        </Container>
      </Grid>
      <Grid item style={{ width: "50%" }}>
        <Container>
          <Typography
            variant="overline"
            style={{ color: "gray", paddingRight: "3px" }}
            gutterBottom
          >
            Date client:
          </Typography>

          {car && (
            <>
              <Typography variant="subtitle2" gutterBottom>
                Client/Utilizator: {client?.name}
              </Typography>
              <Typography variant="body2">
                Adresa: {client?.adresa}
                {", "}
                {client?.localitate}
                {", "}
                {client?.judet}
              </Typography>
              <Typography variant="body2">CUI/CNP: {client?.cuicnp}</Typography>
              <Typography variant="body2">
                Contact: {client?.contact}
              </Typography>
              <Typography variant="body2">
                Telefon: {client?.telefon}
              </Typography>
            </>
          )}
        </Container>
      </Grid>
    </>
  );
};

export default VehClient;
