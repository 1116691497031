import React, { useState, useEffect } from "react";
import { Grid, Container, Typography } from "@mui/material";
import { Modal, Button as Button2 } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import InspectiiFinale from "./InspectiiFinale";
import { getSingleFiles, deleteFile } from "../../../data/api";

const ListaInspectiiFinale = ({ idDeviz }) => {
  const [modalVisibility, setModalVisibilty] = useState(false);
  const [singleFiles, setSingleFiles] = useState([]);

  const handleClickOpen = () => {
    setModalVisibilty(true);
  };

  const getSingleFileslist = async () => {
    try {
      const fileslist = await getSingleFiles(idDeviz.id);
      setSingleFiles(fileslist);
    } catch (error) {}
  };

  const deleteSingleFile = async (file) => {
    await deleteFile(file);
    getSingleFileslist();
  };

  useEffect(() => {
    getSingleFileslist();
  }, []);

  return (
    <>
      <Grid
        item
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Container>
          <div className="d-flex justify-content-between">
            <Typography
              variant="overline"
              style={{ color: "gray", paddingRight: "3px" }}
              gutterBottom
            >
              Inspectii finale si diagnoze:
            </Typography>

            <Button2
              style={{ width: 145 }}
              type="primary"
              onClick={handleClickOpen}
            >
              Adauga fisier
            </Button2>
          </div>
        </Container>
        <Container>
          <div>
            {singleFiles.map((file, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <a
                    href={`/${file.filePath.slice(19)}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black" }}
                  >
                    {file.title} - {file.fileName}
                  </a>
                </div>
                <div>---</div>
                <div>
                  <DeleteOutlined
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => deleteSingleFile(file)}
                  />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Grid>

      {modalVisibility && (
        <Modal
          onCancel={() => {
            setModalVisibilty(false);
          }}
          open={modalVisibility}
          title="Adauga o inspectie tehnica sau diagnoza"
          centered
          footer={false}
          width={600}
        >
          <InspectiiFinale
            idDeviz={idDeviz}
            getsingle={() => getSingleFileslist()}
          />
        </Modal>
      )}
    </>
  );
};

export default ListaInspectiiFinale;
