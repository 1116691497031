import React, { useEffect, useState } from "react";
import { useGetDevizeQuery, useDeleteDevizMutation } from "./devizeApiSlice";
import { useGetCarsQuery } from "../cars/carsApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table, Button, message, Divider, Input } from "antd";
import { useNavigate } from "react-router-dom";

function DevizeList() {
  const [devizeData, setDevizeData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  const { id } = useAuth();

  const { cars } = useGetCarsQuery(id, {
    selectFromResult: ({ data }) => ({
      cars: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  function getCar(id) {
    let foundCar = cars.find((e) => e._id === id);
    return foundCar;
  }

  const {
    data: devize,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetDevizeQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const [deleteDeviz] = useDeleteDevizMutation();

  const getDevize = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(devize?.entities).map(
        (key) => devize.entities[key]
      );
      setDevizeData(result);
    }
  };

  useEffect(() => {
    getDevize();
    // eslint-disable-next-line
  }, [devize]);

  const onDeleteDevizClicked = async (record) => {
    try {
      message.success("Devizul a fost sters cu succes");

      await deleteDeviz({ id: record }).unwrap();
    } catch (err) {
      message.error("Nu se poate sterge acest deviz!");
    }
  };

  function checkStatusText(status) {
    return status === "inAsteptare"
      ? "In Asteptare"
      : status === "inLucru"
      ? "In lucru"
      : status === "reparat"
      ? "Vehicul gata de predat"
      : status === "predat"
      ? "Vehicul predat"
      : status === "oferta"
      ? "Oferta"
      : "red";
  }

  function checkStatus(status) {
    const fix = {
      padding: "4px 12px",
      borderRadius: "20px",
      minWidth: "170px",
      textAlign: "center",
    };
    return status === "inAsteptare"
      ? {
          ...fix,
          border: "solid 0px #1976d2",
          backgroundColor: "#baddff",
        }
      : status === "inLucru"
      ? {
          ...fix,
          border: "solid 0px #45edea",
          backgroundColor: "#45edea",
        }
      : status === "reparat"
      ? {
          ...fix,
          border: "solid 0px #d99959",
          backgroundColor: "#d99959",
        }
      : status === "predat"
      ? {
          minWidth: "170px",
          padding: "4px 12px",
          textAlign: "center",
        }
      : status === "oferta"
      ? {
          ...fix,
          border: "solid 0px #ffffff",
          backgroundColor: "#ffffff",
        }
      : "red";
  }

  //_________________________________________________________________

  const date = (timestamp) => new Date(timestamp).toLocaleString();

  //__________________________________________________________________

  const columns = [
    {
      title: "Numar deviz",
      dataIndex: "numarDeviz",
    },
    {
      title: "Stare",
      dataIndex: "status",
      render: (status) => (
        <div className="d-flex">
          <div style={checkStatus(status)}>{checkStatusText(status)}</div>
        </div>
      ),
    },
    {
      title: "Marca",
      dataIndex: "car",
      render: (car) => (
        <div className="d-flex">
          <span>{getCar(car).marca}</span>
        </div>
      ),
    },
    {
      title: "Model",
      dataIndex: "car",
      render: (car) => (
        <div className="d-flex">
          <span>{getCar(car).model}</span>
        </div>
      ),
    },
    {
      title: "Nr. Inmatriculare",
      dataIndex: "car",
      render: (car) => (
        <div className="d-flex">
          <span>{getCar(car).nrinmatriculare}</span>
        </div>
      ),
    },
    {
      title: "Serie caroserie",
      dataIndex: "car",
      render: (car) => (
        <div className="d-flex">
          <span>{getCar(car).vin}</span>
        </div>
      ),
    },
    {
      title: "Data adaugarii",
      dataIndex: "createdAt",
      render: (createdAt) => <div>{date(createdAt)}</div>,
    },
    {
      title: "Data modificarii",
      dataIndex: "updatedAt",
      render: (updatedAt) => <div>{date(updatedAt)}</div>,
    },
    {
      title: "Modificari",
      dataIndex: "id",
      render: (id, record) => (
        <div className="d-flex">
          <EditOutlined
            className="mx-2"
            onClick={() => navigate(`/dash/devize/${id}`)}
          />
          <DeleteOutlined
            className="mx-2"
            onClick={() => onDeleteDevizClicked(id)}
          />
        </div>
      ),
    },
  ];

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  const handleChange = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    let resultsCar = [];
    devizeData?.forEach((o) => {
      if (
        getCar(o.car).vin.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getCar(o.car)
          .nrinmatriculare.toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        resultsCar.push(o);
      }
    });
    resultsCar.reverse();
    setSearchResults(resultsCar);

    // eslint-disable-next-line
  }, [searchTerm, devizeData]);

  content = (
    <>
      <div className="d-flex align-items-center">
        <h4 className="">Cautare:</h4>
        <div style={{ marginLeft: "10px" }}>
          <Input
            type="search"
            // size="68"
            style={{
              width: 300,
            }}
            value={searchTerm}
            onChange={handleChange}
            placeholder="VIN, nr. inmatriculare"
          />
        </div>
      </div>
      <Divider />
      <div className="d-flex justify-content-between">
        <h3>Devize</h3>
        <Button
          type="primary"
          onClick={() => {
            localStorage.removeItem("idCar");
            navigate("/dash/devize/new");
          }}
        >
          Deviz nou
        </Button>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      {isSuccess && (
        <Table
          columns={columns}
          dataSource={searchResults}
          bordered
          pagination={{
            defaultPageSize: 20,
            pageSizeOptions: ["20", "40", "60", "80", "100"],
            showSizeChanger: true,
          }}
          onRow={(r) => ({
            onDoubleClick: () => navigate(`/dash/devize/${r.id}`),
          })}
        />
      )}
    </>
  );

  return content;
}

export default DevizeList;
