import React, { useEffect, useState } from "react";
import {
  useGetMechanicsQuery,
  useUpdateMechanicMutation,
  useDeleteMechanicMutation,
  useAddNewMechanicMutation,
} from "./mechanicsApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Checkbox, Input, message, Modal, Table } from "antd";
import { SketchPicker } from "react-color";

function MechanicsList() {
  const [mechanicsData, setMechanicsData] = useState([]);
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");
  const [show, setShow] = useState(false);

  const { id } = useAuth();

  const {
    data: mechanics,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMechanicsQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [deleteMechanic] = useDeleteMechanicMutation();

  const [updateMechanic] = useUpdateMechanicMutation();

  const onDeleteMechanicClicked = async (record) => {
    try {
      message.success("Mecanicului a fost sters cu succes");
      await deleteMechanic({ id: record }).unwrap();
    } catch (err) {
      message.error(
        "Nu se poate sterge acest mecanic exista informatii in baza de date legate de el"
      );
    }
  };

  const [addNewMechanic] = useAddNewMechanicMutation();

  const getMechanics = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(mechanics?.entities).map(
        (key) => mechanics.entities[key]
      );
      setMechanicsData(result);
    }
  };

  useEffect(() => {
    getMechanics();
    // eslint-disable-next-line
  }, [mechanics]);

  //_________________________________________________________________

  const date = (timestamp) => new Date(timestamp).toLocaleString();

  //__________________________________________________________________

  const columns = [
    {
      title: "Culoare",
      dataIndex: "color",
      render: (color) => (
        <div
          style={{
            backgroundColor: `${color}`,
            width: 25,
            height: 25,
            border: "2px solid white",
          }}
        ></div>
      ),
      width: 50,
    },
    {
      title: "Nume mecanic",
      dataIndex: "name",
    },
    {
      title: "Stare angajare",
      dataIndex: "employee",
      render: (employee) => (
        <div className="d-flex">
          {employee ? (
            <span>Angajat</span>
          ) : (
            <span style={{ color: "red" }}>Nu mai lucreaza</span>
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.employee - b.employee,
      },
      sortOrder: "descend",
      // defaultSortOrder: "descend",
    },
    {
      title: "Data adaugarii",
      dataIndex: "createdAt",
      render: (createdAt) => <div>{date(createdAt)}</div>,
    },
    {
      title: "Data modificarii",
      dataIndex: "updatedAt",
      render: (updatedAt) => <div>{date(updatedAt)}</div>,
    },
    {
      title: "Modificari",
      dataIndex: "id",
      render: (id, record) => (
        <div className="d-flex">
          <EditOutlined
            className="mx-2"
            onClick={() => {
              setEditingItem(record);
              setBlockPickerColor(record.color);
              setAddEditModalVisibilty(true);
            }}
          />
          <DeleteOutlined
            className="mx-2"
            onClick={() => onDeleteMechanicClicked(id)}
          />
        </div>
      ),
    },
  ];

  const onFinish = async (values) => {
    if (editingItem === null) {
      const canSave = [values.name, id].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await addNewMechanic({
            user: id,
            name: values.name,
            color: blockPickerColor,
          }).unwrap();
          /// se pune unrap ca este in interiorul try catch block
          message.success("Mecanicul a fost adaugat cu succes");
          setAddEditModalVisibilty(false);
        } catch (err) {
          message.error("Nu se poate salva acest mecanic", err);
        }
      } else {
        message.error("Introdu un nume de mecanic");
      }
    } else {
      const canSave = [values.name, values.user].every(Boolean) && !isLoading;
      if (canSave) {
        try {
          await updateMechanic({
            id: values.id,
            user: values.user,
            name: values.name,
            color: blockPickerColor,
            employee: values.employee,
          }).unwrap();

          message.success("Datele mecanicului modificate cu succes");
          setAddEditModalVisibilty(false);
          setEditingItem(null);
        } catch (err) {
          message.error("Nu se poate modifica acest mecanic");
        }
      } else {
        message.error("Introdu un nume de mecanic");
      }
    }
  };

  function toggleShow() {
    setShow(!show);
  }

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      <div className="d-flex justify-content-between">
        <h3>Mecanici</h3>
        <Button
          style={{ width: 155 }}
          type="primary"
          onClick={() => setAddEditModalVisibilty(true)}
        >
          Adauga Mecanic
        </Button>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      {isSuccess && (
        <Table
          columns={columns}
          dataSource={mechanicsData}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ["5", "10"],
            showSizeChanger: true,
          }}
        />
      )}

      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null);
            setAddEditModalVisibilty(false);
            setShow(false);
          }}
          open={addEditModalVisibilty}
          centered
          title={`${
            editingItem !== null ? "Modifica mecanic" : "Adauga mecanic"
          }`}
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item name="id" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="user" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            {editingItem && (
              <Form.Item
                name="employee"
                label="Stare angajare"
                valuePropName="checked"
              >
                <Checkbox>Angajat?</Checkbox>
              </Form.Item>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                onClick={toggleShow}
                style={{
                  backgroundColor: `${blockPickerColor}`,
                  width: 30,
                  height: 30,
                  border: "2px solid white",
                }}
              ></div>{" "}
              Alege o culoare
              {show && (
                <SketchPicker
                  color={blockPickerColor}
                  onChange={(color) => {
                    setBlockPickerColor(color.hex);
                  }}
                />
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button htmlType="submit" type="primary">
                {`${editingItem !== null ? "Salveaza" : "Adauga"}`}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );

  return content;
}

export default MechanicsList;
