import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import Logo from "../../resourses/logo.png";

import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

const Login = () => {
  useTitle("Auto deviz - Login");

  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();
  const [isSignUp, setIsSignUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken } = await login({ username, password }).unwrap();

      dispatch(setCredentials({ accessToken }));
      setUsername("");
      setPassword("");
      navigate("/dash");
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
      errRef.current.focus();
    }
  };

  const handleChange = () => {
    console.log("date de inregistare");
  };

  const handleUserInput = (e) => setUsername(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  const errClass = errMsg ? "errmsg" : "offscreen";
  //console.log(errClass); //

  if (isLoading) return <PulseLoader color={"#FFF"} />;

  const content = (
    <div className="Auth">
      {/* left side */}

      <div className="a-left">
        {/* <img src={Logo} alt="" /> */}

        <div className="Webname">
          <h1>Autoviva</h1>
        </div>
      </div>

      {/* right form side */}

      <div className="a-right">
        <form className="infoForm authForm" onSubmit={handleSubmit}>
          <h3>{isSignUp ? "Inregistrare" : "Autentificare"}</h3>
          {isSignUp && (
            <div>
              <input
                required
                type="text"
                placeholder="Numele"
                className="infoInput"
                name="firstname"
                // value={data.firstname}
                onChange={handleChange}
              />
              <input
                required
                type="text"
                placeholder="Numele de familie"
                className="infoInput"
                name="lastname"
                // value={data.lastname}
                onChange={handleChange}
              />
            </div>
          )}

          <div>
            <input
              required
              type="text"
              placeholder="Username"
              className="infoInput"
              name="username"
              ref={userRef}
              value={username}
              onChange={handleUserInput}
              autoComplete="off"
            />
          </div>
          <div>
            <input
              required
              type="password"
              className="infoInput"
              placeholder="Parola"
              name="password"
              value={password}
              onChange={handlePwdInput}
            />
            {isSignUp && (
              <input
                required
                type="password"
                className="infoInput"
                name="confirmpass"
                placeholder="Confirmare parola"
                onChange={handleChange}
              />
            )}
          </div>

          {/* <span
            style={{
              color: "red",
              fontSize: "12px",
              alignSelf: "flex-end",
              marginRight: "5px",
              //display: confirmPass ? "none" : "block",
            }}
          >
            *Confirm password is not same
          </span> */}
          {!isSignUp && (
            <label htmlFor="persist" className="form__persist">
              <input
                type="checkbox"
                className="form__checkbox"
                id="persist"
                onChange={handleToggle}
                checked={persist}
              />
              &nbsp;Activare logare prelungita
            </label>
          )}

          <div>
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                // resetForm();
                setIsSignUp((prev) => !prev);
              }}
            >
              {isSignUp
                ? "Already have an account Login"
                : "Don't have an account Sign up"}
            </span>
            <button
              className="button infoButton"
              type="Submit"
              // disabled={loading}
            >
              {isSignUp ? "Inregistrare" : "Conectare"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return content;
};
export default Login;
