import React, { useEffect, useState } from "react";
import { useGetDevizeQuery } from "../devize/devizeApiSlice";
import { useGetCarsQuery } from "../cars/carsApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { Card, Button, Divider } from "antd";
import { useNavigate } from "react-router-dom";

function ServiceList() {
  const [devizeData, setDevizeData] = useState([]);

  const [inAsteptare, setInAsteptare] = useState([]);
  const [inLucru, setInLucru] = useState([]);
  const [reparat, setReparat] = useState([]);

  const navigate = useNavigate();

  const { id } = useAuth();

  const { cars } = useGetCarsQuery(id, {
    selectFromResult: ({ data }) => ({
      cars: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  function getCar(id) {
    let foundCar = cars.find((e) => e._id === id);
    return foundCar;
  }

  const {
    data: devize,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetDevizeQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const getDevize = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(devize?.entities).map(
        (key) => devize.entities[key]
      );
      setDevizeData(result);
    }
  };

  useEffect(() => {
    getDevize();
    // eslint-disable-next-line
  }, [devize]);

  useEffect(() => {
    let inAsteptare = [];
    let inLucru = [];
    let reparat = [];
    devizeData?.forEach((o) => {
      if (o.status === "inAsteptare") {
        inAsteptare.push(o);
      }
      if (o.status === "inLucru") {
        inLucru.push(o);
      }
      if (o.status === "reparat") {
        reparat.push(o);
      }
    });
    setInAsteptare(inAsteptare);
    setInLucru(inLucru);
    setReparat(reparat);

    // eslint-disable-next-line
  }, [devizeData]);

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      <div className="d-flex justify-content-between">
        <h3>Activitate Service</h3>
        <Button
          type="primary"
          onClick={() => navigate("/dash/service/mecanici")}
        >
          Activitate Mecanici
        </Button>
        <Button type="primary" onClick={() => navigate("/dash/devize/new")}>
          Deviz nou
        </Button>
      </div>

      {isError && <p className="errmsg">{error?.data?.message}</p>}

      <Divider />
      <h5>Autovehicule care trebuie sa intre in reparatie</h5>
      <div className="d-flex justify-content-center align-items-center flex-wrap text-dark">
        {isSuccess &&
          inAsteptare.map((item) => (
            <Card
              key={item.id}
              style={{
                width: "290px",
                height: "180px",
                display: "flex",
                flexDirection: "row",
              }}
              className="m-3 "
            >
              <div>Nr. Inmatriculare: {getCar(item.car).nrinmatriculare}</div>
              <div>Serie caroserie: {getCar(item.car).vin}</div>
              <div>Marca: {getCar(item.car).marca}</div>
              <div>Model: {getCar(item.car).model}</div>
              <div>Nr. deviz: {item.numarDeviz}</div>
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => navigate(`/dash/devize/${item.id}`)}
                  className="p-1 m-1 w-50"
                  variant="secondary"
                >
                  Deschide devizul
                </Button>
              </div>
            </Card>
          ))}
      </div>

      <Divider />
      <h5>Autovehicule care sunt in lucru</h5>
      <div className="d-flex justify-content-center align-items-center flex-wrap text-dark">
        {isSuccess &&
          inLucru.map((item) => (
            <Card
              key={item.id}
              style={{
                width: "290px",
                height: "180px",
                display: "flex",
                flexDirection: "row",
              }}
              className="m-3 "
            >
              <div>Nr. Inmatriculare: {getCar(item.car).nrinmatriculare}</div>
              <div>Serie caroserie: {getCar(item.car).vin}</div>
              <div>Marca: {getCar(item.car).marca}</div>
              <div>Model: {getCar(item.car).model}</div>
              <div>Nr. deviz: {item.numarDeviz}</div>

              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => navigate(`/dash/devize/${item.id}`)}
                  className="p-1 m-1 w-50"
                  variant="secondary"
                >
                  Deschide devizul
                </Button>
              </div>
            </Card>
          ))}
      </div>

      <Divider />
      <h5>Autovehicule pregatite pentru predare</h5>
      <div className="d-flex justify-content-center align-items-center flex-wrap text-dark">
        {isSuccess &&
          reparat.map((item) => (
            <Card
              key={item.id}
              style={{
                width: "290px",
                height: "180px",
                display: "flex",
                flexDirection: "row",
              }}
              className="m-3 "
            >
              <div>Nr. Inmatriculare: {getCar(item.car).nrinmatriculare}</div>
              <div>Serie caroserie: {getCar(item.car).vin}</div>
              <div>Marca: {getCar(item.car).marca}</div>
              <div>Model: {getCar(item.car).model}</div>
              <div>Nr. deviz: {item.numarDeviz}</div>
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => navigate(`/dash/devize/${item.id}`)}
                  className="p-1 m-1 w-50"
                  variant="secondary"
                >
                  Deschide devizul
                </Button>
              </div>
            </Card>
          ))}
      </div>
    </>
  );

  return content;
}

export default ServiceList;
