import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const frontOfficesAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = frontOfficesAdapter.getInitialState();

export const frontOfficesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFrontOffices: builder.query({
      query: (id) => ({
        url: `/frontOffices/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedFrontOffices = responseData.map((frontOffice) => {
          frontOffice.id = frontOffice._id;
          return frontOffice;
        });
        return frontOfficesAdapter.setAll(initialState, loadedFrontOffices);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "FrontOffice", id: "LIST" },
            ...result.ids.map((id) => ({ type: "FrontOffice", id })),
          ];
        } else return [{ type: "FrontOffice", id: "LIST" }];
      },
    }),
    addNewFrontOffice: builder.mutation({
      query: (initialFrontOffice) => ({
        url: "/frontOffices",
        method: "POST",
        body: {
          ...initialFrontOffice,
        },
      }),
      invalidatesTags: [{ type: "FrontOffice", id: "LIST" }],
    }),
    updateFrontOffice: builder.mutation({
      query: (initialFrontOffice) => ({
        url: "/frontOffices",
        method: "PATCH",
        body: {
          ...initialFrontOffice,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "FrontOffice", id: arg.id },
      ],
    }),
    deleteFrontOffice: builder.mutation({
      query: ({ id }) => ({
        url: `/frontOffices`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "FrontOffice", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetFrontOfficesQuery,
  useAddNewFrontOfficeMutation,
  useUpdateFrontOfficeMutation,
  useDeleteFrontOfficeMutation,
} = frontOfficesApiSlice;

// returns the query result object
export const selectFrontOfficesResult =
  frontOfficesApiSlice.endpoints.getFrontOffices.select();

// creates memoized selector
const selectFrontOfficesData = createSelector(
  selectFrontOfficesResult,
  (frontOfficesResult) => frontOfficesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllFrontOffices,
  selectById: selectFrontOfficeById,
  selectIds: selectFrontOfficeIds,
  // Pass in a selector that returns the frontOffices slice of state
} = frontOfficesAdapter.getSelectors(
  (state) => selectFrontOfficesData(state) ?? initialState
);
